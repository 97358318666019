/* src/styles/Register.css */

.register-container {
  width: 90%;
  max-width: 400px;
  background-color: transparent;
  border-radius: 20px;
  box-shadow: var(--box-shadow);
  margin: 40px auto;
  padding: 30px;
  text-align: center;
  color: var(--text-color);
  border: 0.5px solid var(--secondary-bg-color); /* Rahmen wie bei den anderen Containern */
}

/* Titel an die anderen Titel anpassen */
.register-container h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: var(--primary-color);
}

.register-container form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.register-container input {
  padding: 10px;
  font-size: 1.1rem; /* Optional: var(--button-font-size) */
  border: 1px solid var(--secondary-bg-color);
  border-radius: 10px;
  background-color: var(--secondary-bg-color);
  box-shadow: var(--box-shadow-inset);
  color: var(--text-color);
  accent-color: var(--btn-success-bg);
}

.register-container button {
  padding: var(--button-padding, 12px 24px);
  font-size: var(--button-font-size, 1.1rem);
  background-color: var(--btn-success-bg, #28a745);
  color: var(--btn-success-text, white);
  border: none;
  border-radius: var(--button-radius, 10px);
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.register-container button:hover:not(:disabled) {
  background-color: var(--btn-success-bg-hover, #218838);
  transform: scale(1.05);
}

.register-container button:disabled {
  background-color: var(--btn-secondary-bg, #6c757d);
  cursor: not-allowed;
  transform: none;
}

.register-container .error {
  color: var(--price-down-color, red);
  font-size: 0.9rem;
}

.register-container .links {
  margin-top: 20px;
  text-align: center;
}

.register-container .links a {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.register-container .links a:hover {
  color: var(--primary-hover-color);
}

/* "Angemeldet bleiben?"-Funktionalität */
.register-container .remember-me {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-top: -10px;
}

.register-container .remember-me input[type="checkbox"] {
  width: 18px;
  height: 18px;
  accent-color: var(--btn-success-bg);
  cursor: pointer;
}

.register-container .remember-me label {
  font-size: 1rem;
  color: var(--text-color);
  cursor: pointer;
}

.grecaptcha-badge {
  display: block !important;
  visibility: visible !important;
  bottom: 100px !important;   /* Verschieben vom unteren Rand (z. B. 100px) */
  opacity: 1 !important;
  z-index: 9999 !important;
}


/* Responsive Anpassungen */
@media (max-width: 768px) {
  .register-container {
    width: 95%;
    padding: 20px;
  }

  .register-container button {
    font-size: var(--button-font-size, 1rem);
    width: 100%;
  }

  .register-container .remember-me {
    justify-content: center;
    gap: 5px;
  }

  .register-container .remember-me label {
    font-size: 0.9rem;
  }

  .grecaptcha-badge {
    bottom: 50px !important;   /* Verschieben vom unteren Rand (z. B. 100px) */
  }
}



.register-container .password-toggle {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Wichtig, falls das Eltern-Element Center-Einstellungen hat */
  gap: 8px;
  margin-top: -10px;
}

.register-container .password-toggle input[type="checkbox"] {
  width: 14px;
  height: 14px;
  accent-color: var(--btn-success-bg);
  cursor: pointer;
}

.register-container .password-toggle label {
  font-size: 0.8rem;
  color: var(--text-color);
  cursor: pointer;
}