/* src/styles/AdminConfig.css */

/* Container / Layout wie gehabt */
.admin-config-container {
    width: 90%;
    max-width: 1200px;
    margin: 40px auto;
    padding: 20px;
    background-color: var(--background-color2);
    border-radius: 20px;
    box-shadow: var(--box-shadow);
    border: 1px solid var(--secondary-bg-color);
    color: var(--text-color);
  }
  
  .admin-config-container h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
    color: var(--primary-color);
  }
  
  /* Tabs-Leiste */
  .admin-config-tabs {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .admin-config-tabs button {
    padding: 10px 16px;
    border: 1px solid var(--secondary-bg-color);
    border-radius: 6px;
    background-color: var(--background-color2);
    color: var(--text-color);
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .admin-config-tabs button:hover {
    background-color: var(--background-color-hover);
  }
  
  .admin-config-tabs button.active {
    border: 1px solid var(--primary-color);
    background-color: var(--background-color-hover);
  }
  
  /* Formular-Abschnitt */
  .admin-config-form {
    margin-top: 10px;
  }
  
  .form-group {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    font-weight: bold;
    margin-bottom: 6px;
  }
  
  .form-group input,
  .form-group textarea,
  .form-group select {
    background-color: var(--background-color2);
    border: 1px solid var(--secondary-bg-color);
    border-radius: 4px;
    color: var(--text-color);
    padding: 8px;
  }
  
  .form-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .form-buttons button {
    background-color: var(--background-color2);
    border: 1px solid var(--secondary-bg-color);
    border-radius: 6px;
    padding: 10px 16px;
    cursor: pointer;
    color: var(--text-color);
  }
  
  .form-buttons button:hover {
    background-color: var(--background-color-hover);
  }
  
  /* Tab-Content Container */
  .tab-content {
    margin-top: 20px;
  }
  