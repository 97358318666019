/* src/styles/verifyEmail.css */

.verify-email-container {
    width: 90%;
    max-width: 400px;
    background-color: transparent;
    border-radius: 20px;
    box-shadow: var(--box-shadow);
    margin: 40px auto;
    padding: 30px;
    text-align: center;
    color: var(--text-color);
    border: 0.5px solid var(--secondary-bg-color);
  }
  
  .links {
    margin-top: 20px;
    text-align: center;
  }
  
  .links a {
    color: var(--primary-color);
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .links a:hover {
    color: var(--primary-hover-color);
  }
  
  @media (max-width: 768px) {
    .verify-email-container {
      width: 95%;
      padding: 20px;
    }
  }
  