* {
  box-sizing: border-box;
}

.coin-details-container {
  width: 90%;
  max-width: 1200px;
  background-color: transparent;
  border-radius: 20px;
  box-shadow: var(--box-shadow);
  margin: 40px auto;
  padding: 0px;
  color: var(--text-color);
  border: 0px solid var(--secondary-bg-color);
}

.back-button {
  background-color: var(--btn-primary-bg);
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 20px;
}

.back-button:hover:not(:disabled) {
  background-color: var(--btn-primary-bg-hover);
}

.back-button:disabled {
  background-color: var(--btn-secondary-bg);
  cursor: not-allowed;
}

.coin-details-controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
  gap: 20px;
}

.coin-details-currency-selector {
  display: flex;
  align-items: center;
  gap: 10px;
  /* Label und CoinSelektor mit Abstand */
}

.coin-details-currency-selector label {
  margin-right: 10px;
}

.coin-details-currency-selector select {
  padding: 5px 10px;
  border-radius: 10px;
  border: 1px solid var(--secondary-bg-color);
  background-color: var(--secondary-bg-color);
  color: var(--text-color);
}

.coin-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
}

.logo-and-title {
  display: flex;
  align-items: center;
  gap: 10px;
}

.volume-change {
  margin-left: 4px;
}

.market-cap-rank-badge {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border-radius: 50%;
  padding: 5px 10px;
  font-size: 0.8rem;
  font-weight: bold;
  border: 1px solid var(--primary-color);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(3px);
}

.coin-logo-large {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.coin-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--primary-color);
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.coin-price-display {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 15px;
}


@keyframes highlight-up-combined {
  0%   { background-color: transparent; color: var(--text-color); }
  50%  { background-color: transparent; color: var(--price-up-color); }
  100% { background-color: transparent; color: var(--text-color); }
}
@keyframes highlight-down-combined {
  0%   { background-color: transparent; color: var(--text-color); }
  50%  { background-color: transparent; color: var(--price-down-color); }
  100% { background-color: transparent; color: var(--text-color); }
}

.coin-price-value {
  display: inline-block;
  font-size: 1.4rem;
  /* etwas kleiner als der 2rem-Titel */
  font-weight: bold;
  color: var(--text-color);
  line-height: 1.2;
}


/* Anwenden der Animationen über die NEUEN, SPEZIFISCHEN Klassen */
.coin-price-value.coin-price-highlight-up { /* <-- Geänderter Selektor */
  /* Die globalen .price-up { color: green; } Regeln werden ignoriert */
  animation: highlight-up-combined 0.7s ease-out 1;
}

.coin-price-value.coin-price-highlight-down { /* <-- Geänderter Selektor */
  /* Die globalen .price-down { color: red; } Regeln werden ignoriert */
  animation: highlight-down-combined 0.7s ease-out 1;
}

.coin-info-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px;
}

.coin-info-grid>div {
  background-color: transparent;
  border: 0.5px solid var(--secondary-bg-color);
  border-radius: 10px;
  padding: 20px;
  box-shadow: var(--box-shadow);
  line-height: 1.1;
  color: var(--text-color);
  flex: 1 1 calc(50% - 20px);
  box-sizing: border-box;
}

.coin-info-grid>div h3 {
  margin-top: 0;
  margin-bottom: 15px;
  color: var(--primary-color);
  font-size: 1.4rem;
  font-weight: bold;
}

.coin-info-grid>div p {
  margin: 3px 0;
  line-height: 1.2;
}

.coin-info-grid>div p strong {
  color: var(--primary-color);
}

/* Card für Beschreibung über volle Breite, falls vorhanden */
.coin-description-card {
  background-color: transparent;
  border: 0.5px solid var(--secondary-bg-color);
  border-radius: 10px;
  padding: 20px;
  box-shadow: var(--box-shadow);
  line-height: 1.5;
  color: var(--text-color);
  margin-bottom: 20px;
}

.coin-description-card h3 {
  margin-top: 0;
  margin-bottom: 15px;
  color: var(--primary-color);
  font-size: 1.5rem;
  font-weight: bold;
}

/* Links-Karte: eigene Karte, kein zusätzlicher Abstand notwendig */
.coin-links a {
  color: var(--text-color);
  text-decoration: none;
}

.coin-links a:hover {
  text-decoration: underline;
}

.loading-spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.spinner {
  margin-bottom: 10px;
  width: 40px;
  height: 40px;
  border: 4px solid #ccc;
  border-top-color: var(--primary-color);
  border-radius: 50%;
  animation: spin 0.6s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.error-message,
.loading {
  text-align: center;
  margin: 20px 0;
  font-size: 1.1rem;
  font-weight: bold;
  color: var(--text-color);
}

/* Responsive Anpassungen */
@media (max-width: 768px) {
  .coin-details-container {
    width: 95% !important;
    max-width: none;
    /* Entfernt die 1200px-Grenze */
    margin: 20px auto;
    /* Weniger Rand an den Seiten */
    padding: 0px;
    overflow-x: hidden;
  }

  .coin-logo-large {
    width: 35px;
    height: 35px;
  }

  .market-cap-rank-small {
    /* Font-Größe minimal kleiner, aber durch andere Styles deutlicher */
    font-size: 1rem;
  }

  .coin-info-grid>div,
  .coin-description-card,
  .aktiveMarkets_container,
  .coin-supply-card {
    padding: 10px;
    /* oder ein anderer, geringerer Wert */
  }

  .aktiveMarkets_container {
    padding: 10px !important;
  }

  /* Alle Cards jetzt 100% */
  .coin-chart-card,
  .candlestick-chart-card,
  .coin-description-card,
  .aktiveMarkets_container,
  .coin-info-grid>div {
    width: 100%;
    margin: 0 auto 20px auto;
    /* z.B. auto links/rechts, 20px unten */
  }

  /* Chart-Container innerhalb der Cards auf 100% */
  .chart-container {
    position: relative;
    width: 100%;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .coin-chart-header {
    flex-wrap: wrap;
    margin-left: 0;
  }

  .coin-title {
    font-size: 1.3rem;
    display: inline-block;
    /* Sicherstellen, dass max-width greift */
    max-width: 18ch;
    /* Maximal 80 Zeichen breit */
    white-space: nowrap;
    /* Kein Zeilenumbruch */
    overflow: hidden;
    /* Überlauf verstecken */
    text-overflow: ellipsis;
    /* Ellipsis einfügen */
  }

  .coin-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .coin-details-controls {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .coin-info-grid {
    flex-direction: column;
  }

  .coin-info-grid>div {
    flex: 1 1 100%;
    width: 100%;
  }

  .coin-price-value {
    font-size: 1.2rem;
  }

  .coin-price-display {
    margin-left: 0px;
  }
}

.short-link {
  display: inline-block;
  max-width: 325px;
  /* Breite nach Bedarf anpassen */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.short-link:hover {
  text-decoration: underline;
}

.link-icon {
  margin-right: 5px;
  margin-left: 7px;
  vertical-align: middle;
  color: var(--text-color);
}

/* CoinChart Card */
.coin-chart-card {
  background-color: transparent;
  border: 0.5px solid var(--secondary-bg-color);
  border-radius: 10px;
  padding: 2px;
  box-shadow: var(--box-shadow);
  margin-bottom: 20px;
  color: var(--text-color);
}

.coin-chart-card h3 {
  margin-top: 0;
  margin-bottom: 15px;
  color: var(--primary-color);
  font-size: 1.5rem;
  font-weight: bold;
}

.coin-chart-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 0 10px;
  margin-bottom: -10px;
}

.coin-chart-title {
  margin: 0;
  color: var(--primary-color);
  font-size: 1.5rem;
  font-weight: bold;
}

.favstar-coindetails {
  margin-top: -3px;
  margin-left: -8px;
}

.coin-chart-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* Damit Label/Select links und Button rechts */
  margin-bottom: 15px;
  padding: 10px;
  gap: 10px;
  /* Abstand zwischen Label und Auswahlbox */
}

.coin-chart-header label {
  margin-right: 5px;
}

.coin-chart-header select {
  padding: 5px 10px;
  border-radius: 10px;
  border: 1px solid var(--background-color2);
  background-color: var(--secondary-bg-color);
  color: var(--text-color);
}

.chart-toggle-button {
  background-color: var(--secondary-bg-color);
  color: var(--text-color);
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  gap: 5px;

  margin-left: auto;
  /* Hält den Button dauerhaft am rechten Rand */
}

.chart-toggle-button:hover {
  background-color: var(--btn-primary-bg-hover);
}

.chart-toggle-button svg {
  font-size: 1.2rem;
}

/* Nur für Candlestick-Chart */
.candlestick-chart-card {
  background-color: transparent;
  border-radius: 10px;
  margin-bottom: 20px;
  border: 0.5px solid var(--secondary-bg-color);
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  height: 650px;
}

.candlestick-chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: var(--secondary-bg-color);
}

.candlestick-chart-container {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.candlestick-chart-container>div {
  width: 100% !important;
  height: 100% !important;
  display: block;
}

.chart-container {
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.custom-legend {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 5px;
  padding: 5px 0;
  font-size: 22px;
  font-weight: bold;
  white-space: nowrap;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

/* Wenn das Eltern-Element (z. B. .coin-chart-card oder .candlestick-chart-card) im Fullscreen ist */
.coin-chart-card:fullscreen .chart-container,
.candlestick-chart-card:fullscreen .chart-container {
  height: 100vh;
  width: 100vw;
  background-color: transparent;
  overflow: hidden;
}

.coin-price-change-container {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
}

.coin-price-change-value.green {
  color: #4caf50;
}

.coin-price-change-value.red {
  color: #f44336;
}

.coin-price-change-value.neutral {
  color: #aaa;
}

.coin-supply-card {
  background-color: var(--secondary-bg-color);
  border-radius: 10px;
  padding: 20px;
  box-shadow: var(--box-shadow);
  color: var(--text-color);
}

.coin-supply-card h3 {
  margin-top: 0;
  margin-bottom: 15px;
  color: var(--primary-color);
  font-size: 1.5rem;
  font-weight: bold;
}

.coin-supply-card p {
  margin: 10px 0;
}

.coin-supply-card p strong {
  color: var(--primary-color);
}

/* Progress-Bar für den prozentualen Anteil */
.supply-progress-container {
  position: relative;
  width: 100%;
  background-color: var(--background-color2);
  border-radius: 10px;
  margin-top: 15px;
  height: 20px;
  overflow: hidden;
}

.supply-progress-bar {
  background-color: #4caf50;
  height: 100%;
  position: relative;
}

.supply-percent-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-weight: bold;
  text-shadow: 0 0 2px #000;
  font-size: 0.9rem;
}

.supply-explanation {
  margin-top: 10px;
  font-size: 0.9rem;
  color: var(--text-color);
}

.no-data .supply-progress-bar-empty {
  background-color: #ccc;
  width: 100%;
  height: 100%;
}

.supply-progress-bar-empty {
  background-color: #ccc;
}

.last-updated-info {
  font-size: 0.9rem;
  color: var(--text-color);
}

.cap-volume-info {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.market-cap-rank-small {
  /* Font-Größe minimal kleiner, aber durch andere Styles deutlicher */
  font-size: 1.16rem;
  color: var(--text-color);
  display: inline-block;
}

.market-cap-info,
.volume-24h-info {
  font-size: 1rem;
  color: var(--text-color);
}

.icon-market-cap,
.icon-volume {
  color: var(--primary-color);
  font-size: 1rem;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.custom-tooltip {
  position: absolute;
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 12px;
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  font-size: 0.9rem;
  z-index: 999;
  top: 100%;
  right: 0;
  transform: none;
  margin-top: 5px;
  min-width: 200px;
}

.tooltip-close-button {
  position: absolute;
  top: 4px;
  right: 4px;
  background: none;
  border: none;
  color: var(--text-color);
  font-size: 0.8rem;
  line-height: 1;
  cursor: pointer;
  padding: 0;
}

.tooltip-close-button:hover {
  color: var(--primary-color);
}

.tooltip-icon {
  font-size: 1.1rem;
  color: var(--primary-color);
  cursor: help;
  vertical-align: middle;
  margin-left: 5px;
  margin-top: -3px;
}

.tooltip-icon:hover {
  color: var(--primary-color2);
}

.aktiveMarkets_container {
  background-color: transparent;
  border: 0.5px solid var(--secondary-bg-color);
  border-radius: 10px;
  padding: 20px;
  box-shadow: var(--box-shadow);
  color: var(--text-color);
  margin-bottom: 20px;
}

.aktiveMarkets_container h3 {
  margin-top: 0;
  margin-bottom: 15px;
  color: var(--primary-color);
  font-size: 1.5rem;
  font-weight: bold;
}

.aktiveMarkets_table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 15px;
}

.aktiveMarkets_table thead th {
  background-color: transparent;
  color: var(--text-color);
  font-weight: bold;
  padding: 10px;
  border-bottom: 0.5px solid var(--secondary-bg-color);
  text-align: left;
}

.aktiveMarkets_table tbody td {
  padding: 10px;
  border-bottom: 1px solid var(--secondary-bg-color);
  font-size: 0.9rem;
}

.aktiveMarkets_table a {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: bold;
}

.aktiveMarkets_table a:hover {
  text-decoration: underline;
}

.aktiveMarkets_paginationControls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.aktiveMarkets_paginationControls button {
  background-color: var(--btn-primary-bg);
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.aktiveMarkets_paginationControls button:hover:not(:disabled) {
  background-color: var(--btn-primary-bg-hover);
}

.aktiveMarkets_paginationControls button:disabled {
  background-color: var(--btn-secondary-bg);
  cursor: not-allowed;
}

.aktiveMarkets_paginationControls span {
  font-size: 0.9rem;
  color: var(--text-color);
}

/* Tooltip und Icon */
.aktiveMarkets_infoIcon {
  margin-left: 5px;
  cursor: pointer;
  color: var(--text-color);
  font-size: 0.9rem;
}

.aktiveMarkets_infoIcon:hover {
  color: var(--primary-color);
}

.aktiveMarkets_mobileTooltip {
  position: absolute;
  top: 100%;
  left: 0;
  background: var(--background-color2);
  color: var(--text-color);
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: var(--box-shadow);
  white-space: nowrap;
  z-index: 9999;
}

.aktiveMarkets_pairCell {
  position: relative;
  max-width: 150px;
  white-space: nowrap;
}

.aktiveMarkets-filter-bar {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.aktiveMarkets-selector {
  padding: 5px 10px;
  border-radius: 10px;
  border: 1px solid var(--secondary-bg-color);
  background-color: var(--background-color2);
  color: var(--text-color);
}

.aktiveMarkets-reset-button {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--text-color);
  display: flex;
  align-items: center;
  padding: 0;
}

.aktiveMarkets-reset-button:hover {
  color: var(--background-color2);
}

.aktiveMarkets-info-text {
  margin-bottom: 10px;
  font-size: 0.9rem;
  color: #666;
}

/* Market Logo */
.aktiveMarkets-market-logo {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 5px;
}

/* Trust Score */
.aktiveMarkets-trust-indicator {
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  vertical-align: middle;
}

.aktiveMarkets-trust-green {
  background-color: green;
}

.aktiveMarkets-trust-yellow {
  background-color: yellow;
}

.aktiveMarkets-trust-warn {
  vertical-align: middle;
}

.aktiveMarkets-trust-red {
  color: red;
}

.aktiveMarkets-trust-gray {
  color: #999;
}

.aktiveMarkets-trust-cell {
  white-space: nowrap;
}

.aktiveMarkets-trust-label {
  font-size: 0.9rem;
  color: var(--text-color);
  margin-left: 5px;
}

.aktiveMarkets-disabled {
  cursor: not-allowed;
  opacity: 0.5;
  background-color: #444;
}

/* Responsive: Märkte-Tabelle als Karten-Layout */
@media (max-width: 768px) {
  .aktiveMarkets_table thead {
    display: none;
  }

  .aktiveMarkets_table tbody {
    display: block;
    width: 100%;
  }

  .aktiveMarkets_table tr {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    background-color: var(--background-color2);
    border: 1px solid var(--secondary-bg-color);
    border-radius: 10px;
    padding: 10px;
    box-shadow: var(--box-shadow);
    width: 100%;
  }

  .aktiveMarkets_table td {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    width: 100%;
    position: relative;
    word-break: break-word;
  }

  .aktiveMarkets_table td:before {
    content: attr(data-label);
    font-weight: bold;
    margin-right: 10px;
    color: var(--primary-color);
    white-space: normal;
  }

  .aktiveMarkets-filter-bar {
    flex-direction: column;
    align-items: flex-start;
  }

  .aktiveMarkets_paginationControls {
    flex-direction: column;
    gap: 5px;
  }

  .aktiveMarkets_pairCell {
    max-width: 100%;
    white-space: normal;
  }
}

.category-badges-wrapper {
  position: relative;
  width: 100%;
  padding: 0 6px;
}

.category-badges-container {
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
  overflow-x: auto;
  padding: 8px 0;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  touch-action: pan-x;
}

.category-badges-container::-webkit-scrollbar {
  display: none;
}

.category-badge {
  flex: 0 0 auto;
  width: auto;
  background-color: transparent;
  border: 0.5px solid var(--primary-color);
  color: var(--text-color);
  border-radius: 10px;
  padding: 4px 8px;
  font-size: 0.9rem;
  white-space: nowrap;
  cursor: pointer;
  transition: background-color 0.2s;
}

.category-badge:hover {
  background-color: var(--primary-color-hover);
}

/* OrderbookDisplay Styles */
.orderbook-display {
  background-color: transparent;
  border: 0.5px solid var(--secondary-bg-color);
  border-radius: 10px;
  padding: 20px;
  box-shadow: var(--box-shadow);
  margin-bottom: 20px;
  color: var(--text-color);
}

.orderbook-display h3 {
  margin-top: 0;
  margin-bottom: 15px;
  color: var(--primary-color);
  font-size: 1.5rem;
  font-weight: bold;
}

/* Desktop: Container mit fester Höhe */
.orderbook-tables {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  height: 300px;
}

/* Bids & Asks scrollable */
.orderbook-tables .orderbook-bids,
.orderbook-tables .orderbook-asks {
  flex: 1 1 45%;
  overflow-y: auto;
  max-height: 100%;
}

/* Tabellen-Layout */
.orderbook-tables table {
  width: 100%;
  table-layout: fixed;
}

.orderbook-tables th,
.orderbook-tables td {
  padding: 8px;
  text-align: left;
  border-bottom: 0.5px solid var(--secondary-bg-color);
  font-size: 0.9rem;
  color: var(--text-color);
}

.orderbook-tables th {
  background-color: transparent;
  font-weight: bold;
}

/* Mobile: Bids/Asks übereinander */
@media (max-width: 768px) {
  .orderbook-tables {
    flex-direction: column;
    gap: 10px;
    height: auto;
  }

  .orderbook-tables .orderbook-bids,
  .orderbook-tables .orderbook-asks {
    max-height: 200px;
  }
}

/* TradesDisplay Styles */
.trades-display {
  background-color: transparent;
  border: 0.5px solid var(--secondary-bg-color);
  border-radius: 10px;
  padding: 20px;
  box-shadow: var(--box-shadow);
  margin-bottom: 20px;
  color: var(--text-color);
}

.trades-display h3 {
  margin-top: 0;
  margin-bottom: 15px;
  color: var(--primary-color);
  font-size: 1.5rem;
  font-weight: bold;
}

.trades-display table {
  width: 100%;
  table-layout: fixed;
}

.trades-display th,
.trades-display td {
  padding: 8px;
  text-align: left;
  border-bottom: 0.5px solid var(--secondary-bg-color);
  font-size: 0.9rem;
  color: var(--text-color);
}

.trades-display th {
  background-color: transparent;
  font-weight: bold;
}

/* Hintergrund für Buy/Sell */
.trade-buy {
  background-color: rgba(76, 175, 80, 0.1);
}

.trade-sell {
  background-color: rgba(244, 67, 54, 0.1);
}

.skeleton-cell {
  display: block;
}

.market-maker {
  border-left: 2px dashed #ff7f57;
}

@media (max-width: 768px) {
  .trades-display table {
    table-layout: auto;
  }
}

/* Market-Data: 24h High/Low, ATH/ATL, etc. */
.market-data-top {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 15px;
}

.market-data-bottom {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.market-data-top .market-data-item,
.market-data-bottom .market-data-item {
  flex: 0 0 calc(33.33% - 15px);
  display: flex;
  flex-direction: column;
}

.market-data-item strong {
  margin-bottom: 3px !important;
  font-size: 1rem;
  color: var(--primary-color);
}

.market-data-value {
  font-size: 1rem;
  margin-bottom: 5px;
}

.ath-info,
.atl-info {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--background-color2);
  border-radius: 8px;
  padding: 3px 8px;
  max-width: max-content;
}

.date-info,
.percent-info {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  font-size: 0.9rem;
}

.ath-info .percent-info,
.atl-info .percent-info {
  order: 1;
  margin-bottom: 2.6px;
}

.ath-info .date-info,
.atl-info .date-info {
  order: 2;
}

.date-info .icon-date,
.percent-info .icon-percent {
  color: var(--primary-color);
  font-size: 0.75rem;
}

.time-info {
  margin-left: 8px;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  font-size: 0.9rem;
  background-color: var(--background-color2);
  padding: 2px 6px;
  border-radius: 5px;
}

.time-info .icon-time {
  color: var(--primary-color);
  font-size: 0.9rem;
}

.volume-info .market-data-value {
  margin-bottom: 0;
}

.volume-label {
  font-size: 0.85rem;
  font-weight: normal;
  color: var(--primary-color);
  display: inline-flex;
  align-items: center;
  margin-right: 4px;
}

.volume-change-box {
  display: inline-flex;
  align-items: center;
  background-color: var(--background-color2);
  border-radius: 8px;
  padding: 3px 8px;
  max-width: max-content;
  margin-top: 5px;
}

.volume-change-box .icon-percent {
  font-size: 0.70rem;
}

/* Mobile Market-Data: als Grid */
@media (max-width: 768px) {
  .coin-market-extremes {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
    gap: 10px;
  }

  .market-data-top,
  .market-data-bottom {
    display: contents;
  }

  .high-info {
    grid-column: 1;
    grid-row: 1;
  }

  .low-info {
    grid-column: 2;
    grid-row: 1;
  }

  .marketcap-info {
    grid-column: 1;
    grid-row: 2;
  }

  .volume-info {
    grid-column: 2;
    grid-row: 2;
  }

  .ath-item {
    grid-column: 1;
    grid-row: 3;
  }

  .atl-item {
    grid-column: 2;
    grid-row: 3;
  }
}

.supply-info-items {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.supply-info-item {
  margin-bottom: 0;
  width: 100%;
}

.supply-info-item strong {
  display: inline-block;
  color: var(--primary-color);
  margin-bottom: 3px !important;
  font-size: 1rem;
}

.supply-info-item.max-supply,
.supply-info-item.total-supply,
.supply-info-item.circulating-supply {
  width: calc(33% - 5px);
}

.supply-value {
  font-size: 1rem;
  margin: 0;
}

@media (max-width: 768px) {
  .supply-info-items {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .supply-info-item {
    margin-bottom: 0;
    width: 100%;
  }

  .supply-info-item.max-supply,
  .supply-info-item.total-supply {
    width: calc(50% - 5px);
  }

  .supply-info-item.circulating-supply {
    width: 100%;
  }
}

.coin-price-change-container {
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
}

.price-change-box {
  display: inline-flex;
  align-items: center;
  background-color: var(--background-color2);
  border-radius: 8px;
  padding: 2px 4px;
  gap: 3px;
  max-width: max-content;
}

.timeframe-label {
  font-size: 0.9rem;
  font-weight: bold;
  color: var(--primary-color);
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 3px;
  background-color: transparent;
  border-radius: 4px;
  padding: 2px 4px;
  transition: background-color 0.2s;
}

.dropdown-arrow {
  font-size: 0.8rem;
  transition: transform 0.2s;
}

.dropdown-arrow.open {
  transform: rotate(180deg);
}

.price-change-icon {
  font-size: 0.70rem;
  color: var(--primary-color);
}

.price-change-value {
  font-size: 0.9rem;
  color: var(--primary-color);
  margin-left: 1.5px;
  padding-right: 2px;
}

.timeframe-dropdown {
  position: absolute;
  top: 110%;
  left: 0;
  background-color: var(--background-color2);
  border-color: #fff;
  border-radius: 8px;
  margin-top: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 999;
  overflow: hidden;
  transition: opacity 0.2s ease, transform 0.2s ease;
}

.timeframe-option {
  padding: 8px 12px;
  cursor: pointer;
  color: var(--text-color);
  transition: background-color 0.2s ease;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.timeframe-option:last-child {
  border-bottom: none;
}

.timeframe-option:hover {
  background-color: var(--secondary-bg-color);
}

.timeframe-option.active {
  background-color: var(--secondary-bg-color);
  color: #fff;
}

.marketcaprank-box {
  display: inline-flex;
  align-items: center;
  background-color: var(--background-color2);
  border-radius: 8px;
  padding: 3px 8px;
  gap: 5px;
  margin-top: 2px;
  width: auto;
  max-width: max-content;
  align-self: flex-start;
}

.icon-marketcaprank {
  font-size: 0.7rem;
  color: var(--primary-color);
}

.marketcaprank-value {
  font-size: 0.85rem;
  color: var(--primary-color);
  font-weight: normal;
}

.marketcaprank-arrow.up {
  color: var(--price-up-color);
  margin-left: 2px;
  font-size: 0.65rem;
}

.marketcaprank-arrow.down {
  color: var(--price-down-color);
  margin-left: 2px;
  font-size: 0.65rem;
}

.marketcaprank-change {
  font-size: 0.85rem;
  color: var(--primary-color);
  margin-right: 4px;
}

/* ----------------------
   OVERLAY-STYLES
-----------------------*/

/* Overlay beim Laden */
.chart-loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(72, 72, 72, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
  /* halbtransparentes Weiß */
}

/* Ladespinner (umbenannt zu chart-spinner) */
.chart-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #ccc;
  border-top-color: var(--primary-color);
  border-radius: 50%;
  animation: chartSpinnerSpin 0.6s linear infinite;
  margin-bottom: 10px;
}

@keyframes chartSpinnerSpin {
  to {
    transform: rotate(360deg);
  }
}

/* Overlay bei Fehler */
.chart-error-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 0, 0, 0.2);
  color: #900;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  text-align: center;
  font-weight: bold;
  padding: 10px;
  box-sizing: border-box;
}


