/* src/components/UserInfoModal.css */

/* Overlay, das den Bildschirm abdunkelt */
.user-info-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); /* Leicht abgedunkelter Hintergrund */
    z-index: 9999; /* Ganz oben */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Inhalt des Modals, angelehnt an den coin-list-container-Style */
  .user-info-modal-content {
    width: 90%;
    max-width: 600px;             /* Etwas schmaler als die CoinList */
    background-color: var(--background-color2);
    border-radius: 10px;
    box-shadow: var(--box-shadow);
    border: 1px solid var(--secondary-bg-color);
    padding: 20px;
    color: var(--text-color);
    position: relative; /* damit wir z.B. Positionierungen innerhalb haben können */
    /* optional: margin: 40px auto; -> nicht nötig, da wir zentriert sind */
  }
  
  /* Button-Stil im Modal */
  .user-info-modal-button {
    background-color: var(--btn-primary-bg);
    color: #fff;
    border: none;
    border-radius: 10px;
    padding: 8px 16px;
    margin-top: 20px;  /* etwas Abstand nach oben */
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 1rem;
  }
  
  .user-info-modal-button:hover {
    background-color: var(--btn-primary-bg-hover);
  }
  
  /* Optional: Bei sehr schmalen Bildschirmen kann man das Modal flexibler machen */
  @media (max-width: 768px) {
    .user-info-modal-content {
      max-width: 90%;
      padding: 16px;
    }
  
    .user-info-modal-button {
      width: 100%;
      margin-top: 16px;
    }
  }
  