/* src/styles/GlobalData.css */

#global-data-container {
  width: 90%;
  max-width: 1200px;
  background-color: transparent;
  border-radius: 20px;
  box-shadow: var(--box-shadow);
  margin: 40px auto;
  padding: 0px;
  color: var(--text-color);
  border: 0px solid var(--secondary-bg-color);
}

#global-data-title {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 2rem;
  color: var(--primary-color);
}

.intro-text {
  text-align: center;
  font-size: 1rem;
  margin: 10px auto 20px;
  margin-bottom: 30px;
  max-width: 800px;
  color: var(--text-color);
  line-height: 1.2;
}

#global-data-loading-container {
  text-align: center;
  margin-top: 20px;
}

#global-data-error {
  text-align: center;
  margin: 20px 0;
  font-size: 1.1rem;
  font-weight: bold;
  color: var(--price-down-color);
}

.data-card {
  background-color: transparent;
  border-top: 0.5px solid var(--secondary-bg-color);
  border-bottom: 0.5px solid var(--secondary-bg-color);
  border-left: none;
  border-right: none;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: var(--box-shadow);
}

.data-card h3 {
  margin-bottom: 10px;
  font-size: 1.5rem;
  color: var(--primary-color);
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.select-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.select-container label {
  margin: 0;
  font-size: 1rem;
  color: var(--text-color);
}

#days-select {
  padding: 5px 10px;
  border-radius: 10px;
  border: 1px solid var(--secondary-bg-color);
  background-color: var(--secondary-bg-color);
  color: var(--text-color);
}

.custom-legend {
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 15px;
  color: var(--text-color, #ffffff);
  /* Fallback auf Weiß */
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  color: var(--text-color);
}

.legend-color {
  width: 14px;
  height: 14px;
  border-radius: 4px;
}

.GlobalData_BackgroundPercentage-rect {
  display: inline-block;
  padding: 4px 8px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-weight: bold;
  font-size: 12px;
}

.GlobalData_BackgroundPercentage-up {
  background-color: rgba(26, 170, 12, 0.2);
  color: #1faa0c;
}

.GlobalData_BackgroundPercentage-down {
  background-color: rgba(255, 0, 0, 0.2);
  color: #ff0000;
}

.GlobalData_BackgroundPercentage-neutral {
  background-color: rgba(153, 153, 153, 0.2);
  color: #999999;
}


.chartjs-legend .legend-item {
  color: var(--text-color, #ffffff) !important;
  /* Schriftfarbe auf Weiß setzen */
}

@media (max-width: 768px) {
  #global-data-container {
    width: 95%;
    padding: 10px;
    border: 0.5px solid var(--secondary-bg-color);
  }

  /* Reduzierte Innenabstände für alle Cards */
  .data-card {
    padding: 10px;
    margin-bottom: 10px;
    /* vorher: 20px */
  }

  /* Container für die drei Cards: Reduziere den Abstand zwischen den Cards */
  .global-cards-container {
    display: flex;
    flex-direction: column;
    /* Alle Cards untereinander */
    gap: 10px;
    /* Statt 20px */
    margin-bottom: 10px;
    /* Abstand zum nächsten Element (z. B. Chart) */
  }

  /* Falls einzelne Spalten genutzt werden (falls in manchen Fällen 2 nebeneinander möglich sind),
     den Abstand innerhalb der Spalte ebenfalls reduzieren */
  .global-cards-container .card-column {
    gap: 10px;
    /* Statt 20px */
  }

  .custom-legend {
    gap: 10px;
  }

  .legend-item {
    font-size: 10px;
    gap: 5px;
  }

  /* Verschiebung der Prozentangaben im Line-Chart */
  .data-card .chart-datalabel {
    transform: translateX(-30px);
  }

  /* Pie-Chart: Keine Labels anzeigen */
  #dominance-pie-chart canvas {
    max-height: 350px;
  }

  .data-card h3 {
    font-size: 1.2rem;
  }
}





/* ========== Mobile Legend Styles ========== */

/* Nur auf kleinen Screens sichtbar,
   notfalls zusätzlich im @media (max-width: 768px) einbetten */
.dominance-mobile-legend {
  margin-top: 10px;
  /* z.B. Wrap und zentrieren */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
  /* Falls zu viele Items, kann man die Höhe begrenzen und scrollen lassen */
  max-height: 120px;
  overflow-y: auto;
}

.mobile-legend-item {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 10px;
  /* Falls du's größer willst, nimm 12px */
  color: var(--text-color);
}

.mobile-legend-color {
  width: 12px;
  height: 12px;
  border-radius: 3px;
}

.mobile-legend-label {
  /* Du kannst hier beliebig stylen */
}



.global-cards-container {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;
  /* Ermöglicht das Umbrechen */
}

.global-cards-container .card-column {
  flex: 1 1 300px;
  /* Jede Spalte hat eine Mindestbreite von ca. 300px */
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (max-width: 768px) {
  .global-cards-container {
    flex-direction: column;
  }

  /* Verhindere, dass die Spalten (Cards) sich strecken */
  .global-cards-container .card-column {
    flex: 0 0 auto;
  }
}



@media (min-width: 1024px) {
  #dominance-pie-chart {
    max-width: 800px;
    /* Erhöht den verfügbaren Platz für den Pie Chart */
  }
}