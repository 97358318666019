/* Sicherstellen, dass der Toast-Container vor dem Header liegt */
.Toastify__toast-container {
    position: fixed;
    z-index: 20000;
    top: 20% !important;
    bottom: auto !important;
    transform: translateY(-50%);
}

/* Toast als Flex-Container anordnen */
.Toastify__toast {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    padding: 0.5em 1em;
}

.Toastify__toast-body {
    margin-right: 1em;
}

.Toastify__close-button {
    position: static;
    margin-left: 1em;
    color: var(--text-color);
}

/* Auf mobilen Geräten (bis 768px): Toast nach unten verlagern */
@media (max-width: 768px) {
    .Toastify__toast-container {
        top: auto !important;
        bottom: 20px !important;
        transform: none !important;
        width: 90%;
        max-width: 300px;
        margin: 0 auto; /* Zentrieren */
        left: 0;
        right: 0;
    }
    
    .Toastify__close-button {
        display: none !important;
    }
}

/* Dark Mode: Toast-Hintergrund und Textfarbe anpassen */
body.dark-mode .Toastify__toast {
    background-color: var(--background-color2);
    color: var(--text-color);
}

/* Dark Mode: Close-Button (SVG) heller machen */
body.dark-mode .Toastify__close-button > svg {
    fill: #ffffff;
}
