/* ========== ISOLIERTER RESET & FULLSCREEN ==========
   Dieses CSS wirkt nur auf die CryptoJourney-Komponente und überschreibt globale Styles.
*/
.crypto-journey-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #000;
  overflow: hidden;
  z-index: 9999;
  /* Alle Standard-Stile werden zurückgesetzt */
  all: unset;
  display: block;
}

/* Der Canvas füllt den gesamten Bildschirm */
.crypto-canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

/* Wrapper für das interaktive Intro – komplett im Vollbild, zentriert */
.interactive-intro-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
  pointer-events: none;
}

/* Zentrales Overlay, das Info-Boxen und den "Weiter"-Button enthält */
.intro-overlay {
  position: relative;
  z-index: 110;
  text-align: center;
  width: 90%;
  max-width: 800px;
  pointer-events: none;
}

/* Moderne Info-Boxen für Hotspots */
.hotspot-info {
  background: rgba(0, 0, 0, 0.8);
  margin: 0.5rem auto;
  padding: 1rem 1.5rem;
  border-radius: 12px;
  font-size: 1.2rem;
  color: #fff;
  box-shadow: 0 4px 12px rgba(0,0,0,0.3);
  animation: fadeIn 0.5s ease-out;
  pointer-events: auto;
}

/* Moderner "Weiter"-Button */
.continue-button {
  margin-top: 1.5rem;
  padding: 0.8rem 1.6rem;
  background: linear-gradient(135deg, #ff6a00, #ee0979);
  border: none;
  border-radius: 30px;
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s ease;
  pointer-events: auto;
}

.continue-button:hover {
  background: linear-gradient(135deg, #ee0979, #ff6a00);
}

/* Instruktionstext – klein, kursiv und dezent leuchtend */
.instruction-text {
  margin-top: 1rem;
  font-size: 1rem;
  font-style: italic;
  color: #ffcc00;
  pointer-events: none;
}

/* Für nicht-interaktive Schritte: zentrierte Box mit Text */
.step-placeholder {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 110;
  width: 90%;
  max-width: 800px;
  background: rgba(0, 0, 0, 0.6);
  padding: 2rem;
  border-radius: 12px;
  text-align: center;
  color: #fff;
  backdrop-filter: blur(8px);
  box-shadow: 0 8px 16px rgba(0,0,0,0.4);
}

/* Schritt-Titel für nicht-interaktive Schritte, oben zentriert */
.step-title {
  position: fixed;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 120;
  font-size: 2.4rem;
  color: #fff;
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

/* Sanftes Einblenden */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to   { opacity: 1; transform: translateY(0); }
}

/* ========== MOBILE BREAKPOINTS ========== */
@media (max-width: 480px) {
  .step-title {
    font-size: 1.8rem;
    top: 3%;
  }
  .step-placeholder {
    width: 95%;
    padding: 1.5rem;
  }
  .hotspot-info {
    font-size: 1rem;
    padding: 0.8rem 1rem;
  }
  .continue-button {
    font-size: 1rem;
    padding: 0.6rem 1rem;
  }
}
