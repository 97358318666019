/* ---------------------------------------------
   Gleiche Maße wie in CoinList.css
   => 90% / 1200px etc.
---------------------------------------------- */
.editprofile-container {
  width: 90%;
  max-width: 1200px;
  background-color: transparent;
  border-radius: 20px;
  box-shadow: var(--box-shadow);
  margin: 20px auto;
  padding: 20px;
  margin-top: 40px;
  color: var(--text-color);
  border: 0.5px solid var(--secondary-bg-color);
}

/* Mobil: 95%, overflow-x: hidden 
   (Genauso wie du es in CoinList.css definiert hast)
*/
@media (max-width: 768px) {
  .editprofile-container {
    width: 95%;
    max-width: 1200px;
    overflow-x: hidden;
  }
}

/* Titel (ähnlich coin-list-title) */
.editprofile-container h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
  color: var(--primary-color);
}

/* Formular in 2 Spalten ab 768px */
.editprofile-form {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 8px; /* oder 6px, je nach Geschmack */
}

@media (min-width: 768px) {
  .editprofile-form {
    grid-template-columns: 1fr 1fr;
    row-gap: 8px;    /* vertikaler Abstand */
    column-gap: 20px; /* horizontaler Abstand */
  }
}

/* Eingabe-Gruppen (Label + Input) */
.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: 600;
  color: var(--primary-color);
}

/* Input Style */
.editprofile-form input {
  padding: 6px;
  font-size: 1.1rem;
  border: 1px solid var(--secondary-bg-color);
  border-radius: 10px;
  background-color: var(--secondary-bg-color);
  box-shadow: var(--box-shadow-inset);
  color: var(--text-color);
  accent-color: var(--primary-color);
  width: 100%;
}

/* Buttons (Speichern & Abbrechen) über 2 Spalten */
.editprofile-form-buttons {
  grid-column: 1 / -1;
  display: flex;
  gap: 20px;
  justify-content: center; /* oder space-between */
}

/* Speichern */
.save-button {
  padding: 12px 24px;
  background-color: var(--btn-success-bg, #28a745);
  color: var(--btn-success-text, #fff);
  border: none;
  border-radius: 10px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}
.save-button:hover {
  background-color: var(--btn-success-bg-hover, #218838);
  transform: scale(1.05);
}

/* Abbrechen */
.cancel-button {
  padding: 12px 24px;
  background-color: #6c757d; /* grau */
  color: #fff;
  border: none;
  border-radius: 10px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}
.cancel-button:hover {
  background-color: #5a6268;
  transform: scale(1.05);
}

/* Roter "Benutzerkonto löschen" */
.delete-account-button {
  background-color: #dc3545;
  color: #fff;
  margin-top: 30px;
  padding: 12px 24px;
  border: none;
  border-radius: 10px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}
.delete-account-button:hover {
  background-color: #c82333;
  transform: scale(1.03);
}

/* ---------------------------------------------
   Modal (dark overlay + box)
---------------------------------------------- */
.confirm-delete-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.confirm-delete-modal-content {
  background-color: var(--background-color2, #fff);
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  text-align: center;
  color: var(--text-color, #000);
}

.confirm-delete-modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

/* Modal: Abbrechen-Button (grau) */
.cancel-delete-button {
  background-color: #6c757d;
  color: #fff;
  padding: 12px 24px;
  border-radius: 8px;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.cancel-delete-button:hover {
  background-color: #5a6268;
}

/* Modal: Bestätigen-Button (rot) */
.confirm-delete-button {
  background-color: #dc3545;
  color: #fff;
  padding: 12px 24px;
  border-radius: 8px;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 5px;
}
.confirm-delete-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.confirm-delete-button:hover:enabled {
  background-color: #c82333;
}

/* Optional: Kalender-Icon ausblenden */
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}



/* ------ Social-Login-Teil (Google) -------- */
.social-google-link {
  width: 33%;
  margin-top: 60px;
  padding: 15px;
  background-color: transparent;
  border: 0.1px solid var(--text-color);
  border-radius: 10px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.social-google-link button {
  background-color: var(--secondary-bg-color);
  color: #fff;
  padding: 10px 16px;
  border: 0.5px solid var(--text-color);
  border-radius: 6px;
  cursor: pointer;
  margin-top: 10px;
}
.social-google-link button:hover {
  background-color: #3367d6;
}



@media (max-width: 768px) {
  .social-google-link {
    width: 95%;
  }
}