/* src/styles/Login.css */

.login-container {
  width: 90%;
  max-width: 400px;
  background-color: transparent;
  border-radius: 20px;
  box-shadow: var(--box-shadow);
  margin: 40px auto;
  padding: 30px;
  text-align: center;
  color: var(--text-color);
  border: 0.5px solid var(--secondary-bg-color);
  /* Rahmen wie bei anderen Containern */
}

.login-container form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.login-container input {
  padding: 8px 4px;
  font-size: 0.9rem;
  border: 0.5px solid var(--primary-color);
  border-radius: 4px;
  background-color: transparent;
  box-shadow: none;
  color: var(--text-color);
  accent-color: var(--btn-success-bg);
  transition: box-shadow 0.2s ease;
}

.login-container input:focus {
  box-shadow: 0 0 4px var(--primary-color);
  outline: none;
}

.login-container button {
  padding: var(--button-padding, 12px 24px);
  font-size: var(--button-font-size, 1.1rem);
  background-color: var(--btn-success-bg, #28a745);
  color: var(--btn-success-text, white);
  border: none;
  border-radius: var(--button-radius, 10px);
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.login-container button:hover:not(:disabled) {
  background-color: var(--btn-success-bg-hover, #218838);
  transform: scale(1.05);
}

.login-container button:disabled {
  background-color: var(--btn-secondary-bg, #6c757d);
  cursor: not-allowed;
  transform: none;
}

.login-container .error {
  color: var(--price-down-color, red);
  font-size: 0.9rem;
}

.login-container .links {
  margin-top: 20px;
  text-align: center;
}

.login-container .links a {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.login-container .links a:hover {
  color: var(--primary-hover-color);
}

/* Neue Stile für die Bestätigungsnachricht und den Resend-Button */
.login-container .verification-link {
  margin-top: 20px;
  text-align: center;
}

.login-container .verification-link p {
  margin-bottom: 10px;
}

.login-container .resend-button {
  background-color: var(--btn-info-bg, #17a2b8);
  color: var(--btn-info-text, white);
  border: none;
  padding: 10px 15px;
  border-radius: var(--button-radius, 10px);
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-size: 1rem;
}

.login-container .resend-button:hover:not(:disabled) {
  background-color: var(--btn-info-bg-hover, #138496);
  transform: scale(1.05);
}

.login-container .resend-button:disabled {
  background-color: var(--btn-secondary-bg, #6c757d);
  cursor: not-allowed;
  transform: none;
}


.login-container h2 {
  font-size: 2rem;
  /* Optional, je nach gewünschter Größe */
  margin-bottom: 20px;
  /* Optional, für etwas Abstand */
  color: var(--primary-color);
  /* Gleiche Primärfarbe wie in den anderen Titeln */
}


/* Responsive Anpassungen */
@media (max-width: 768px) {
  .login-container {
    width: 95%;
    padding: 20px;
  }

  .login-container button {
    font-size: var(--button-font-size, 1rem);
    width: 100%;
  }

  .login-container .verification-link p {
    display: block;
  }

  .login-container .resend-button {
    display: block;
    width: 100%;
    margin: 0 auto;
  }
}


.google-logo {
  margin-right: 8px;
  font-size: 20px;
  /* Größer oder kleiner, je nach Bedarf */
}



