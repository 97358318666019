/* src/styles/ForgotPassword.css */

.forgot-password-container {
  width: 90%;
  max-width: 400px;
  background-color: transparent;
  border-radius: 20px;
  box-shadow: var(--box-shadow);
  margin: 40px auto;
  padding: 30px;
  text-align: center;
  color: var(--text-color);
  border: 0.5px solid var(--secondary-bg-color); /* Rahmen wie bei den anderen Containern */
}

/* Titel anpassen (falls du einen h2 oder h3 hast) */
.forgot-password-container h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: var(--primary-color);
}

form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

input {
  padding: 10px;
  font-size: var(--button-font-size, 1.1rem);
  border: 1px solid var(--secondary-bg-color);
  border-radius: 10px;
  background-color: var(--secondary-bg-color);
  box-shadow: var(--box-shadow-inset);
  color: var(--text-color);
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

/* Sicherstellung der korrekten Farben im Darkmode */
body.dark-mode input {
  background-color: var(--background-color);
  border-color: var(--primary-hover-color);
  color: var(--text-color);
}

button {
  padding: var(--button-padding, 12px 24px);
  font-size: var(--button-font-size, 1.1rem);
  background-color: var(--btn-info-bg, #6e8efb);
  color: var(--btn-info-text, white);
  border: none;
  border-radius: var(--button-radius, 10px);
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

button:hover:not(:disabled) {
  background-color: var(--btn-info-bg-hover, #007BFF);
  transform: scale(1.05);
}

button:disabled {
  background-color: var(--btn-secondary-bg, #6c757d);
  cursor: not-allowed;
  transform: none;
}

.message {
  color: var(--price-up-color, green);
  font-size: 0.9rem;
}

.error {
  color: var(--price-down-color, red);
  font-size: 0.9rem;
}

.links {
  margin-top: 20px;
  text-align: center;
}

.links a {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.links a:hover {
  color: var(--primary-hover-color);
}

.remember-me {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-top: -10px;
}

.remember-me input[type="checkbox"] {
  width: 18px;
  height: 18px;
  accent-color: var(--btn-success-bg);
  cursor: pointer;
}

.remember-me label {
  font-size: 1rem;
  color: var(--text-color);
  cursor: pointer;
}

@media (max-width: 768px) {
  .forgot-password-container {
    width: 95%;
    padding: 20px;
  }

  button {
    font-size: var(--button-font-size, 1rem);
    width: 100%;
  }
}
