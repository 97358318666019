/* src/styles/legalNotice.css */

/* -----------------------------------
   Gleiche Container-Breite wie CoinList
-------------------------------------- */
.legalnotice-container {
    width: 90%;
    max-width: 1200px;
    background-color: transparent;
    border-radius: 20px;
    box-shadow: var(--box-shadow);
    margin: 40px auto;
    padding: 20px;
    color: var(--text-color);
    border: 0.5px solid var(--secondary-bg-color);
  }
  
  /* Mobile-Variante: 95% Breite, overflow-x: hidden */
  @media (max-width: 768px) {
    .legalnotice-container {
      width: 95%;
      max-width: 1200px;
      background-color: transparent;
      border-radius: 20px;
      box-shadow: var(--box-shadow);
      margin: 40px auto;
      padding: 20px;
      color: var(--text-color);
      border: 0.5px solid var(--secondary-bg-color);
      overflow-x: hidden;
    }
  }
  
  /* Titel (ähnlich coin-list-title) */
  .legalnotice-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
    color: var(--primary-color);
  }
  
  /* Abschnitte */
  .legalnotice-section {
    margin-bottom: 20px;
  }
  
  /* Zwischenüberschrift */
  .legalnotice-section h3 {
    font-size: 1.3rem;
    color: var(--primary-color);
    margin-bottom: 10px;
  }
  
  /* 
     .legalnotice-toprow => 2-Spalten-Layout (Desktop)
     Mobil => 1 Spalte
  */
  .legalnotice-toprow {
    display: grid;
    grid-template-columns: 1fr; /* mobile standard (1 spalte) */
    gap: 20px; 
    margin-bottom: 20px;
  }
  
  @media (min-width: 768px) {
    .legalnotice-toprow {
      grid-template-columns: 1fr 1fr; /* 2 Spalten am Desktop */
    }
  }
  