/* Globale Einstellungen */ 
* {
  box-sizing: border-box;
}

.gainers-losers-container {
  width: 90%;
  max-width: 1200px;
  background-color: transparent;
  border-radius: 20px;
  box-shadow: var(--box-shadow);
  margin: 40px auto;
  padding: 20px;
  color: var(--text-color);
  border: 0px solid var(--secondary-bg-color);
}

.title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
  color: var(--primary-color);
}

/* Angepasster Filterbereich: Labels über den Selects */
.filters {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* statt center – damit beide Filter oben ausgerichtet sind */
  margin-bottom: 20px;
  gap: 20px; /* Abstand zwischen den Filtergruppen */
}

/* Hier stellen wir die Filtergruppen vertikal an */
.filter-group {
  display: flex;
  flex-direction: column; /* vertikale Anordnung: Label über Select */
  align-items: flex-start;
  gap: 4px; /* kleiner Abstand zwischen Label und Select */
}

/* Entferne den bisherigen margin-right für Labels */
.filter-group label {
  margin: 0;
}


.filter-group select {
  padding: 5px 10px;
  border-radius: 10px;
  border: 1px solid var(--secondary-bg-color);
  background-color: var(--secondary-bg-color);
  color: var(--text-color);
}

.loading,
.error-message {
  text-align: center;
  margin: 20px 0;
  font-size: 1.1rem;
  font-weight: bold;
  color: var(--text-color);
}

/* Tabellen-Layout */
.tables-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  overflow-x: hidden;
}

.table-section h3 {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 10px;
  color: var(--primary-color);
}

.gainers-losers-table {
  width: 100%;
  border-collapse: collapse;
}

.gainers-losers-table th,
.gainers-losers-table td {
  text-align: left;
  padding: 10px;
  border-bottom: 1px solid var(--secondary-bg-color);
  vertical-align: middle;
  line-height: 1.2;
  max-width: 100%;
  overflow: hidden;
}

.gainers-losers-table tbody tr:hover {
  background-color: var(--secondary-bg-color);
  cursor: pointer;
}

.coin-info-cell {
  /* Entferne flex, um gleiche Ausrichtung wie bei CoinList zu gewährleisten */
  /* display: flex;
  align-items: center; */
  gap: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Vertikale Ausrichtung von Bild und Text */
.coin-info-cell img,
.coin-info-cell .coin-name,
.coin-info-cell .coin-symbol {
  vertical-align: middle;
}

.coin-logo,
.coin-name,
.coin-symbol {
  display: inline-block;
  vertical-align: middle;
  line-height: 1.2;
}

.coin-logo {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
}

.coin-name {
  font-weight: bold;
  color: var(--text-color);
  margin-right: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.coin-symbol {
  opacity: 0.7;
}

.price-up {
  color: var(--price-up-color);
}

.price-down {
  color: var(--price-down-color);
}

/* Highlight-Effekte */
.highlight-up {
  animation: highlightValueGreen 0.5s ease-in-out;
  border-radius: 3px;
  padding: 1px 2px;
}

.highlight-down {
  animation: highlightValueRed 0.5s ease-in-out;
  border-radius: 3px;
  padding: 1px 2px;
}

@keyframes highlightValueGreen {
  0% { background-color: #e6f7e6; }
  50% { background-color: #dff0df; }
  100% { background-color: transparent; }
}

@keyframes highlightValueRed {
  0% { background-color: #fce6e6; }
  50% { background-color: #f7dcdc; }
  100% { background-color: transparent; }
}

.desktop-only {
  display: inline-block;
}

.mobile-only {
  display: none;
  margin-top: 5px;
}

/* --- Responsive Anpassungen --- */
@media (max-width: 768px) {
  .gainers-losers-container {
    width: 95%;
    max-width: 1200px;
    background-color: transparent;
    border-radius: 20px;
    box-shadow: var(--box-shadow);
    margin: 40px auto;
    padding: 10px;
    color: var(--text-color);
    border: 0.5px solid var(--secondary-bg-color);
    overflow-x: hidden; 
  }

  .title {
    font-size: 1.5rem;
    margin-top: 10px;
  }

  .filters {
    flex-direction: row;       /* Nebeneinander statt untereinander */
    align-items: center;
    justify-content: space-between;
    gap: 10px;                 /* geringerer Abstand */
  }

  .filter-group {
    width: 33%;                /* Jede Filtergruppe nimmt ca. die Hälfte ein */
    margin-bottom: 3px;          /* Kein zusätzlicher margin-bottom */
  }

  .tables-container {
    width: 100%;
    gap: 40px;
    overflow-x: hidden;
  }

  .gainers-losers-table thead {
    display: none;
  }

  .gainers-losers-table tbody {
    display: block;
    width: 100%;
    overflow-x: hidden;
  }

  .gainers-losers-table tr {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    background-color: transparent;
    border: 0.5px solid var(--secondary-bg-color);
    border-radius: 10px;
    padding: 10px;
    box-shadow: var(--box-shadow);
    width: 100%;
    overflow: hidden;
  }

  .gainers-losers-table td {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    width: 100%;
    position: relative;
    overflow: hidden;
    white-space: normal;
    word-break: break-word;
  }

  .gainers-losers-table td:before {
    content: attr(data-label);
    font-weight: bold;
    margin-right: 10px;
    opacity: 0.9;
    color: var(--primary-color);
    flex: 0 0 auto;
    white-space: normal;
    word-break: break-word;
  }

  .gainers-losers-table td.coin-info-cell {
    order: -1;
    justify-content: flex-start;
    max-width: 100%;
    white-space: normal;
    word-break: break-word;
  }

  .coin-name {
    white-space: normal;
    word-break: break-word;
  }

  .desktop-only {
    display: none;
  }

  .coin-symbol {
    display: none;
  }

  .mobile-only {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    white-space: normal;
    word-break: break-word;
  }

  .mobile-only:before {
    content: attr(data-label);
    font-weight: bold;
    margin-right: 10px;
    color: var(--primary-color);
  }
}

/* Stil für das Informations-Icon */
.info-button {
  background: none;
  border: none;
  padding: 0;
  margin-left: 5px;
  font-size: 1rem;
  cursor: pointer;
  color: var(--primary-color);
}

.info-button:focus {
  outline: none;
}

@media (max-width: 768px) {
  .info-button.mobile-only {
    display: inline; /* Sichtbar auf mobilen Geräten */
  }
}

@media (min-width: 769px) {
  .info-button.mobile-only {
    display: none;
  }
}
