/* src/App.css */

/* 
   (A) HTML/BODY => 100% Höhe,
   damit wir min-height: 100vh in React aufspannen können
*/
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

/*
   (B) app-container => 
   Display Flex + Column => 
   Footer am Ende
*/
.app-container {
  display: flex;
  flex-direction: column;
  /* Wichtig: dehnt sich min. auf gesamte Viewport-Höhe */
  min-height: 100vh;
}

/*
   (C) app-content => flex: 1 
   => füllt den Platz
*/
.app-content {
  flex: 1;
}

/* 
   Deine alte .App-header-Klasse kannst du entfernen 
   oder anpassen, falls du sie nicht mehr brauchst
*/
.App-header {
  /* 
     Falls du sie nicht mehr verwendest, 
     kannst du sie entweder löschen oder so lassen. 
  */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #6e8efb, #a777e3);
  color: white;
  text-align: center;
}
