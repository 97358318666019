/* src/styles/CoinList.css */

* {
  box-sizing: border-box;
}

.coin-list-container {
  width: 90%;
  max-width: 1200px;
  background-color: transparent;
  border-radius: 20px;
  box-shadow: var(--box-shadow);
  margin: 40px auto;
  padding: 20px;
  color: var(--text-color);
  border: 0px solid var(--secondary-bg-color);
}

.coin-list-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
  color: var(--primary-color);
}

.coin-list-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  gap: 20px; /* etwas Abstand zwischen den Gruppen */
}

.coins-per-page label {
  margin-right: 10px;
}

.coins-per-page select {
  padding: 5px 10px;
  border-radius: 10px;
  border: 1px solid var(--secondary-bg-color);
  background-color: var(--secondary-bg-color);
  color: var(--text-color);
}

.pagination-controls button {
  background-color: var(--btn-primary-bg);
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination-controls button:hover:not(:disabled) {
  background-color: var(--btn-primary-bg-hover);
}

.pagination-controls button:disabled {
  background-color: var(--btn-secondary-bg);
  cursor: not-allowed;
}

.pagination-controls span {
  margin: 0 10px;
  color: var(--text-color);
}

.coin-list-table {
  width: 100%;
  border-collapse: collapse;
}

.coin-list-table th,
.coin-list-table td {
  text-align: left;
  padding: 10px;
  border-bottom: 1px solid var(--secondary-bg-color);
  vertical-align: middle;
  line-height: 1.2;
}

.coin-list-table th {
  font-weight: bold;
  cursor: default;
}

.coin-list-table tbody tr:hover {
  background-color: var(--secondary-bg-color);
  cursor: pointer; /* Füge diese Zeile hinzu */
}

/* Anpassungen für die Name-Zelle */
.coin-info-cell {
  /* Entferne display:flex; align-items:center; damit alle Zellen einheitlich sind */
  /* display: flex;
  align-items: center; */
  gap: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Sorge dafür, dass Bild, Name und Symbol auf derselben Höhe sitzen */
.coin-info-cell img,
.coin-info-cell .coin-name,
.coin-info-cell .coin-symbol {
  vertical-align: middle;
}

.coin-logo,
.coin-name,
.coin-symbol {
  display: inline-block;
  vertical-align: middle;
  line-height: 1.2;
}

.coin-logo {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
}

.coin-name {
  font-weight: bold;
  color: var(--text-color);
  margin-right: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.coin-symbol {
  opacity: 0.7;
}

.currency-selector {
  display: flex;
  align-items: center;
  gap: 10px;
}

.currency-selector label {
  margin-right: 10px;
}

.currency-selector select {
  padding: 5px 10px;
  border-radius: 10px;
  border: 1px solid var(--secondary-bg-color);
  background-color: var(--secondary-bg-color);
  color: var(--text-color);
}

.price-up {
  color: var(--price-up-color);
  background-color: transparent !important;
}

.price-down {
  color: var(--price-down-color);
  background-color: transparent !important;
}

.price-neutral {
  color: var(--price-neutral-color);
  background-color: transparent !important;
}

.loading,
.error-message {
  text-align: center;
  margin: 20px 0;
  font-size: 1.1rem;
  font-weight: bold;
  color: var(--text-color);
}


.price-value {
  min-width: 80px; /* oder was passt */
  text-align: right;
  /* restliche Eigenschaften */
}

.highlight-up {
  animation: highlightValueGreen 0.5s ease-in-out;
}

.highlight-down {
  animation: highlightValueRed 0.5s ease-in-out;
}





@keyframes highlightValueGreen {
  0% { background-color: #63ff63; }
  50% { background-color: #33ff33; }
  100% { background-color: transparent; }
}
@keyframes highlightValueRed {
  0% { background-color: #ff6363; }
  50% { background-color: #ff3333; }
  100% { background-color: transparent; }
}


@keyframes highlightTextGreen {
  0%   { color: #39d353; }
  50%  { color: #2ea043; }
  100% { color: inherit; }
}

@keyframes highlightTextRed {
  0%   { color: #f85149; }
  50%  { color: #da3633; }
  100% { color: inherit; }
}

.price-numeric-only {
  display: inline-block;
  /* weitere Eigenschaften, z.B. line-height, transition etc. */
}

.price-numeric-only.highlightText-up {
  animation: highlightTextGreen 0.5s ease-in-out;
}
.price-numeric-only.highlightText-down {
  animation: highlightTextRed 0.5s ease-in-out;
}

.desktop-only {
  display: inline-block;
}

.mobile-only {
  display: none;
  margin-top: 5px;
}

.bottom-pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
}

/* Responsive Anpassungen */
@media (max-width: 768px) {
  .coin-list-container {
    width: 95%;
    max-width: 1200px;
    background-color: transparent;
    border-radius: 20px;
    box-shadow: var(--box-shadow);
    margin: 40px auto;
    padding: 10px;
    color: var(--text-color);
    border: 0.5px solid var(--secondary-bg-color);
    overflow-x: hidden;
  }

  .coin-info-cell span:last-child {
    margin-left: auto;
    margin-top: -10px; 
  }

  .coin-list-title {
    font-size: 1.5rem;
    margin-top: 5px;
  }

  .coin-list-controls {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .coins-per-page,
  .currency-selector {
    order: 0;
  }

  .coin-list-table thead {
    display: none;
  }

  .coin-list-table tbody {
    display: block;
    width: 100%;
    overflow-x: hidden;
  }

  .coin-list-table tr {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    background-color: transparent;
    border: 0.5px solid var(--secondary-bg-color);
    border-radius: 10px;
    padding: 10px;
    box-shadow: var(--box-shadow);
    width: 100%;
    overflow: hidden;
  }

  .coin-list-table td {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    width: 100%;
    position: relative;
    overflow: hidden;
    white-space: normal;
    word-break: break-word;
  }

  .coin-list-table td:before {
    content: attr(data-label);
    font-weight: bold;
    margin-right: 10px;
    opacity: 0.9;
    color: var(--primary-color);
    flex: 0 0 auto;
    white-space: normal;
    word-break: break-word;
  }

  .coin-list-table td.coin-info-cell {
    order: -1;
    justify-content: flex-start;
    max-width: 100%;
    white-space: normal;
    word-break: break-word;
  }

  .coin-name {
    display: inline-block;  /* Sicherstellen, dass max-width greift */
    max-width: 23ch;        /* Maximal 80 Zeichen breit */
    white-space: nowrap;    /* Kein Zeilenumbruch */
    overflow: hidden;       /* Überlauf verstecken */
    text-overflow: ellipsis;/* Ellipsis einfügen */
  }

  .desktop-only {
    display: none;
  }

  .coin-symbol {
    display: none;
  }

  .mobile-only {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    white-space: normal;
    word-break: break-word;
  }

  .mobile-only:before {
    content: attr(data-label);
    font-weight: bold;
    margin-right: 10px;
    color: var(--primary-color);
  }

  .bottom-pagination-controls {
    display: flex;
  }

  .pagination-buttons {
    display: flex;
    gap: 10px; /* Abstand bleibt */
  }
}

.loading-spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.spinner {
  margin-bottom: 10px;
  width: 40px;
  height: 40px;
  border: 4px solid #ccc;
  border-top-color: var(--primary-color);
  border-radius: 50%;
  animation: spin 0.6s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}


/* Scrollleisten für Dark Mode */
body.dark-mode::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

body.dark-mode::-webkit-scrollbar-track {
  background: var(--secondary-bg-color);
  border-radius: 4px;
}

body.dark-mode::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 4px;
}

body.dark-mode::-webkit-scrollbar-thumb:hover {
  background: var(--btn-primary-bg-hover);
}

/* Für Firefox */
body.dark-mode {
  scrollbar-width: thin;
  scrollbar-color: var(--primary-color) var(--secondary-bg-color);
}
