/* 
   Container & Tabelle 
*/
.admin-contact-messages-container {
  width: 90%;
  max-width: 1200px;
  margin: 40px auto;
  padding: 20px;
  background-color: var(--background-color2);
  border-radius: 20px;
  box-shadow: var(--box-shadow);
  border: 1px solid var(--secondary-bg-color);
  color: var(--text-color);
}

/* Buttons (einheitlich), egal ob in Tabelle oder Modal */
.admin-contact-messages-button {
  background-color: var(--background-color2);
  border: 1px solid var(--secondary-bg-color);
  border-radius: 6px;
  padding: 6px 12px;
  cursor: pointer;
  color: var(--text-color);
  transition: background-color 0.2s ease;
  font-size: 0.9rem;
  margin: 0;  /* kein default margin */
}
.admin-contact-messages-button:hover {
  background-color: var(--background-color-hover);
}

/* Filter-Bereich oben (optional) */
.admin-contact-messages-filter {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.admin-contact-messages-filter form {
  display: flex;
  gap: 10px;
}

/* Tabelle */
.admin-contact-messages-table {
  width: 100%;
  border-collapse: collapse;
}
.admin-contact-messages-table th,
.admin-contact-messages-table td {
  padding: 12px;
  border-bottom: 1px solid var(--secondary-bg-color);
}

/* Modal Overlay */
.admin-contact-messages-reply-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Modal Container => Header/Body/Footer */
.admin-contact-messages-reply-content {
  background-color: var(--background-color2);
  padding: 0;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  color: var(--text-color);
  box-shadow: var(--box-shadow);
  text-align: left;
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 80vh;
}

/* Modal Header */
.admin-contact-messages-reply-header {
  padding: 20px 24px;
  border-bottom: 1px solid var(--secondary-bg-color);
}
.admin-contact-messages-reply-header h3 {
  margin: 0;
  font-size: 1.4rem;
  color: var(--primary-color);
}

/* Modal Body => scrollable */
.admin-contact-messages-reply-body {
  padding: 20px 24px;
  overflow-y: auto;
  flex: 1;
}

/* Modal Footer */
.admin-contact-messages-reply-footer {
  padding: 16px 24px;
  border-top: 1px solid var(--secondary-bg-color);
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

/* Jeder Verlaufseintrag => Kasten */
.admin-ticket-response-item {
  background-color: var(--background-color-hover);
  border: 2px dashed var(--primary-color);
  border-radius: 6px;
  margin-bottom: 18px;  /* Abstand zu nächstem Eintrag */
  padding: 12px;
}

/* Meta-Info (Absender/Datum) */
.admin-ticket-response-meta {
  font-size: 0.85rem;
  color: var(--text-secondary-color);
  margin-bottom: 6px;
}

/* pre/blockquote styling, falls du Code oder Zitate hast */
.admin-contact-messages-reply-body blockquote {
  margin: 0 0 16px 0;
  padding-left: 10px;
  border-left: 3px solid var(--secondary-bg-color);
  font-style: italic;
  line-height: 1.4;
  color: var(--text-color);
}

.admin-contact-messages-reply-body pre {
  background-color: var(--background-color-hover);
  padding: 8px;
  border-radius: 6px;
  overflow-x: auto;
  line-height: 1.4;
  margin: 0 0 16px 0;
}

/* Textarea (für die neue Antwort) */
.admin-contact-messages-reply-textarea {
  width: 100%;
  resize: vertical;
  border: 1px solid var(--secondary-bg-color);
  border-radius: 4px;
  background-color: var(--background-color2);
  color: var(--text-color);
  padding: 8px 10px;
  font-size: 0.95rem;
  outline: none;
  line-height: 1.4;
  transition: background-color 0.2s ease;
  margin-bottom: 10px;
}
.admin-contact-messages-reply-textarea:focus {
  border-color: var(--primary-color);
}

/* Deutliche Trennung => optionaler HR */
.admin-contact-messages-reply-body hr {
  margin: 24px 0;
  border: 0;
  border-top: 2px solid var(--secondary-bg-color);
  opacity: 0.7;
}
