/* src/styles/ScamGame.css */

/* ========== GENERELLE CONTAINER-GRÖSSE ========== */
#scamgame-home-container,
#scamgame-game-container,
#scamgame-results-container {
  width: 90%;
  max-width: 1200px;
  background-color: transparent;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  margin: 40px auto;
  padding: 20px;
  color: var(--text-color);
  border: 1px solid var(--secondary-bg-color);
}

/* Titel auf allen Screens */
.scamgame-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
  color: var(--primary-color);
}

/* BALANCE-INFO (Kontostand) */
.scamgame-balance {
  text-align: right;
  font-size: 1rem;
  margin-bottom: 10px;
  color: var(--text-color);
}

/* Startseiten-/Home-Intro */
.scamgame-intro {
  margin-bottom: 20px;
}
.scamgame-home-intro-text {
  text-align: center;
}

/* LEVEL-TITEL: */
.scamgame-level-title {
  text-align: center;
  margin-bottom: 10px;
  font-size: 1.2rem;
  color: var(--primary-color);
}

/* Allgemeine Buttons-Gruppe */
.scamgame-buttons {
  margin-top: 20px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

/* ========== SCENARIO-KARTEN ========== */
.scamgame-card {
  background-color: var(--background-color2);
  border: 1px solid var(--secondary-bg-color);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: var(--box-shadow);
  position: relative;
}

/* Übergeordnete Info: z.B. "E-Mail Scam" */
.scamgame-type-info {
  margin-bottom: 15px;
}

/* ========== E-Mail-Stil ========== */
.scamgame-email-view {
  background-color: var(--secondary-bg-color);
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 10px;
}
.email-header p {
  margin: 0;
  font-size: 0.9rem;
}
.email-body {
  margin-top: 10px;
  font-size: 0.95rem;
}

/* ========== Website-Stil ========== */
.scamgame-website-view {
  border: 1px solid #444;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 10px;
}
.website-bar {
  background-color: #ccc;
  color: #333;
  padding: 5px 10px;
  font-weight: bold;
}
.website-content {
  padding: 10px;
}

/* ========== Influencer-Stil ========== */
.scamgame-influencer-view {
  border: 1px solid #666;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: var(--secondary-bg-color);
}
.influencer-header {
  font-weight: bold;
  margin-bottom: 8px;
  color: var(--primary-color);
}
.influencer-content {
  font-size: 0.95rem;
}

/* ========== Fake-ICO-Stil ========== */
.scamgame-fakeico-view {
  border: 1px dashed #999;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: var(--secondary-bg-color);
}
.fakeico-header {
  font-weight: bold;
  margin-bottom: 8px;
  color: var(--primary-color);
}
.fakeico-content {
  font-size: 0.95rem;
}

/* ========== NFT ========== */
.scamgame-nft-view {
  background-color: var(--secondary-bg-color);
  border: 2px dotted var(--primary-color);
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 10px;
}
.nft-topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--primary-color);
  color: var(--text-color);
  padding: 8px 12px;
  border-radius: 4px;
  margin-bottom: 10px;
}
.nft-logo {
  font-weight: bold;
  font-size: 1.1rem;
}
.nft-menu {
  font-size: 0.9rem;
}
.nft-body {
  font-size: 0.95rem;
}

/* ========== Metaverse ========== */
.scamgame-metaverse-view {
  background-color: var(--background-color2);
  border: 2px solid var(--primary-color);
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 10px;
}
.metaverse-headerbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--secondary-bg-color);
  color: var(--text-color);
  padding: 8px 12px;
  border-radius: 4px;
  margin-bottom: 10px;
}
.metaverse-logo {
  font-weight: bold;
  font-size: 1.1rem;
}
.metaverse-menu {
  font-size: 0.9rem;
}
.metaverse-body {
  font-size: 0.95rem;
}

/* ========== DeFi-Rugpull ========== */
.scamgame-defi-view {
  background-color: var(--background-color2);
  border: 2px dashed var(--price-down-color);
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 10px;
}
.defi-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--secondary-bg-color);
  color: var(--text-color);
  padding: 8px 12px;
  border-radius: 4px;
  margin-bottom: 10px;
}
.defi-logo {
  font-weight: bold;
  font-size: 1.1rem;
}
.defi-apys {
  font-size: 0.9rem;
  color: var(--price-down-color);
}
.defi-body {
  font-size: 0.95rem;
}

/* ========== Exchange-Hack / Fake-Support ========== */
.scamgame-exchange-view {
  background-color: var(--background-color2);
  border: 2px solid var(--secondary-bg-color);
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 10px;
}
.exchange-headerbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--secondary-bg-color);
  color: var(--text-color);
  padding: 8px 12px;
  border-radius: 4px;
  margin-bottom: 10px;
}
.exchange-logo {
  font-weight: bold;
  font-size: 1.1rem;
  color: var(--primary-color);
}
.exchange-chat {
  font-size: 0.9rem;
}
.exchange-body {
  font-size: 0.95rem;
}

/* ========== SCAM-Buttons & Feedback ========== */
.scamgame-answer-buttons {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  justify-content: center;
}
.scamgame-feedback {
  margin-top: 10px;
  font-weight: bold;
}
.correct-answer {
  color: var(--price-up-color, green);
}
.wrong-answer {
  color: var(--price-down-color, red);
}

/* ========== RESULTS-SEITE (Meine Ergebnisse) ========== */
.scamgame-results {
  margin-top: 20px;
}
.scamgame-results-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.scamgame-result-icon {
  margin-right: 6px;
  font-size: 1.2rem;
}
.scamgame-result-icon.correct {
  color: var(--price-up-color, green);
}
.scamgame-result-icon.wrong {
  color: var(--price-down-color, red);
}

/* ========== Overlay bei Level-Fail ========== */
.level-failed-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.6);
  z-index: 9999; 
  display: flex;
  align-items: center;
  justify-content: center;
}

.level-failed-content {
  background-color: var(--background-color2);
  border: 2px solid var(--secondary-bg-color);
  border-radius: 10px;
  padding: 20px;
  width: 90%;
  max-width: 500px;
  box-shadow: var(--box-shadow);
  text-align: center;
}

/* ========== RESPONSIVE ANPASSUNGEN ========== */
@media (max-width: 768px) {
  #scamgame-home-container,
  #scamgame-game-container,
  #scamgame-results-container {
    width: 95%;
    padding: 10px;
  }
  .scamgame-card {
    margin-bottom: 15px;
    padding: 15px;
  }
}


/* ========== LINK-BUTTON-STYLING ========== */
.scamgame-link-button {
  padding: 0.6rem 1rem;
  border: 1px solid #ccc;
  border-radius: 6px;
  background: var(--secondary-bg-color);
  color: var(--text-color);
  text-decoration: none;
  cursor: pointer;
  /* Optional: Hover-Effekt */
  transition: background 0.2s ease, color 0.2s ease;
}

.scamgame-link-button:hover {
  background: var(--primary-color);
  color: #fff; /* oder var(--text-color) => je nachdem */
}


/* Für das "Aufklapp"-Detail-Bereich */
.scamgame-result-details {
  margin-top: 6px;
  font-size: 0.95rem;
  background: var(--secondary-bg-color);
  padding: 8px;
  border-radius: 4px;
}

.scamgame-result-item {
  cursor: pointer;
  margin-bottom: 10px;
  padding: 8px;
  border-radius: 6px;
  border: 1px solid #666;
}



.email-topbar {
  margin-bottom: 10px;
  /* Falls du oben/unten etwas Platz brauchst */
  /* padding: 5px; */
  /* border: 1px solid red;  // Nur zum Test */
}

.email-topbar img {
  display: block;
  width: 100%;
  height: auto;
}