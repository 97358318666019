.profile-container {
  width: 90%;
  max-width: 500px;
  background-color: var(--background-color2);
  border-radius: 20px;
  box-shadow: var(--box-shadow);
  margin: 40px auto;
  padding: 30px;
  text-align: left;
  color: var(--text-color);
  border: 1px solid var(--secondary-bg-color); /* Rahmen wie in anderen Containern */
}

.profile-header {
  text-align: center;
  margin-bottom: 20px;
}

.profile-header h2 {
  margin-bottom: 10px;
  color: var(--primary-color); /* Titelfarbe an andere Sektionen angleichen */
}

.profile-info {
  display: grid;
  grid-template-columns: 1fr 2fr; 
  gap: 10px 20px;
  align-items: center;
  margin-bottom: 20px;
}

.profile-info p {
  margin: 0;
  font-size: 1rem;
  word-wrap: break-word;
  color: var(--text-color);
  margin-top: 8px;
}

.profile-info p.label {
  font-weight: bold;
  color: var(--text-color);
  opacity: 0.7; 
  text-align: right;
}

.profile-links {
  text-align: center;
  margin-top: 20px;
}

.profile-links a {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.profile-links a:hover {
  color: var(--primary-hover-color);
}

@media (max-width: 768px) {
  .profile-container {
    width: 95%;
    padding: 20px;
  }

  .profile-info {
    grid-template-columns: 1fr;
  }

  .profile-info p.label {
    text-align: left;
    font-weight: bold;
  }
}
