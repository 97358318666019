/* 
  Header.css
  - Bis 1199px => Mobile (Hamburger)
  - Ab 1200px => Desktop mit Ticker
  - Kein Fokus-/Tap-Rand
  - Icons (React Icons)
*/

/* -----------------------------------------
   RESET
------------------------------------------*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* -----------------------------------------
   BASIS-STILE (ALLE Viewports)
------------------------------------------*/

/* Header/Main */
.header_main {
  position: sticky;
  top: 0;
  z-index: 999;
  width: 100%;
  background-color: var(--background-color2);
  box-shadow: var(--box-shadow);
}

.header_content {
  width: 95%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 10px 0;
}

/* Logo */
.header_logo img {
  display: block;
  width: 120px;
  height: auto;
}

/* 
   Desktop vs. Mobile
   - Desktop: ab 1200px => .header_desktop-only 
   - Mobile:  0–1199px => .header_mobile-only
*/
.header_desktop-only {
  display: none !important;
}
.header_mobile-only {
  display: none !important;
}

/* -----------------------------------------
   NAVIGATION DESKTOP
------------------------------------------*/
.header_nav {
  position: relative;
}
.header_nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.header_nav li {
  position: relative;
}
.header_nav a,
.header_nav span {
  color: var(--text-color);
  text-decoration: none;
  font-weight: 500;             /* statt bold */
  font-size: 0.95rem;           /* etwas kleiner als 1rem */
  letter-spacing: 0.02em;       /* minimaler Letter-Spacing für etwas "Luft" */
  padding: 4px 0;               /* vertikaler Abstand */
  
  /* Übergangseffekte */
  transition: color 0.2s ease-in-out,
              border-bottom-color 0.2s ease-in-out;
  
  /* Dezente Unterstreichung: Start transparent, 
     wird beim Hover/Active gefärbt */
  border-bottom: 2px solid transparent;
  cursor: pointer;
}

.header_nav a:hover,
.header_nav span:hover {
  color: var(--primary-color);
  border-bottom-color: transparent;
}

/* Aktiver Menüpunkt => NavLink .active */
.header_nav a.active {
  color: var(--primary-color);
  font-weight: 600;             /* statt bolder */
  border-bottom-color: var(--primary-color);
}


/* NEU: Auch für die Userlinks im Desktop-Bereich */
.header_user-links a.active {
  color: var(--primary-color);
  font-weight: 600;             /* statt bolder */
  border-bottom-color: var(--primary-color);
}

/* NEU: Auch für das Mobile-Menü */
.header_user-links-mobile a.active {
  color: var(--primary-color);
  font-weight: bolder;
  border-bottom: 2px solid var(--primary-color);
}

/* Desktop-Submenüs */
.header_has-submenu .header_submenu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--background-color2);
  border: 1px solid var(--secondary-bg-color);
  border-radius: 10px; /* Hier Border-Radius hinzugefügt */
  min-width: 180px;
  box-shadow: var(--box-shadow);
  padding: 10px 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s;
}
.header_has-submenu:hover .header_submenu {
  display: block;
  opacity: 1;
  pointer-events: auto;
}
.header_submenu li {
  padding: 5px 15px;
  font-weight: 500;        /* statt bold */
  font-size: 0.95rem;      /* anpassen wie oben */
}
.header_submenu li:hover {
  background-color: var(--secondary-bg-color);
}
.header_has-submenu > span::after {
  content: '▾';
  display: inline-block;
  margin-left: 5px;
  font-size: 0.8rem;
  opacity: 0.7;
}

/* -----------------------------------------
   HEADER ACTIONS (RECHTS)
------------------------------------------*/
.header_actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Für den festen Platz des Pricetickers */
.header_price-ticker-container {
  width: 195px;           /* oder eine andere feste Breite */
  text-align: right;      
  overflow: visible;      /* statt hidden – damit das Dropdown nicht abgeschnitten wird */
  pointer-events: auto;   /* stellt sicher, dass Klicks ankommen */
}

.price-ticker-value {
  font-family: 'Courier New', monospace;
}

/* ICON-GRUPPE */
.header_icon-group {
  display: flex;
  gap: 5px;
  flex-wrap: nowrap;
}

/* -----------------------------------------
   .header_btn => DIV STATT BUTTON
   => Icons wie Glocke, Suche, etc.
------------------------------------------*/
.header_btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
  background: none;
  border: none;
  color: var(--text-color);
  cursor: pointer;
  transition: color 0.2s ease;
  outline: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
}
.header_btn:hover {
  color: var(--primary-color);
  transform: none;
}
.header_btn:focus,
.header_btn:active {
  outline: none;
  box-shadow: none;
  border: none;
  transform: none;
}

/* Desktop-Userlinks */
.header_user-links {
  display: flex;
  align-items: center;
}
.header_user-links a.header_user-link,
.header_user-links span.header_user-link {
  margin-left: 15px;
  color: var(--text-color);
  text-decoration: none;
  font-weight: 500;       /* statt bold */
  font-size: 0.95rem;     /* etwas kleiner als 1rem */
  padding: 4px 0;         /* minimaler Abstand, wie im Hauptmenü */
  border-bottom: 2px solid transparent;
  transition: color 0.2s, border-bottom-color 0.2s;
  cursor: pointer;
}
.header_user-links a.header_user-link:hover,
.header_user-links span.header_user-link:hover {
  color: var(--primary-color);
  border-bottom-color: var(--primary-color);
}

/* Wenn aktiv => active-Klasse oder manuell anpassen, 
   analog zum Hauptmenü */
.header_user-links a.active.header_user-link {
  color: var(--primary-color);
  font-weight: 600; /* statt bolder */
  border-bottom-color: var(--primary-color);
}
/* -----------------------------------------
   MOBILE MENÜ => SEITLICHER DRAWER (rechts)
------------------------------------------*/
/* Overlay: Halbtransparent, füllt gesamten Bildschirm */
.header_mobile-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0,0,0,0.6);
  backdrop-filter: blur(4px);
  z-index: 9999;
}

/* Drawer selbst (rechte Seite) */
.header_mobile-main-nav {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  left: auto !important;
  width: 75%;
  max-width: 300px;
  height: 100%;
  background-color: var(--background-color2);
  border-left: 1px solid var(--secondary-bg-color);
  box-shadow: var(--box-shadow);
  padding: 20px;
  animation: header_slideIn 0.3s ease forwards;
  overflow-y: auto;
  position: relative; /* fürs X oben rechts */
}
@keyframes header_slideIn {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

/* "X" oben rechts im Drawer */
.header_mobile-menu-close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: var(--text-color);
  font-size: 1.4rem;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.2s ease;
}
.header_mobile-menu-close:hover {
  color: var(--primary-color);
}

/* Menü-Liste im Drawer */
.header_mobile-main-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Hauptlinks (Home, Login, Register) & Hauptpunkte (Cryptocurrencies, Tools) */
.header_mobile-main-nav ul > li > a,
.header_mobile-main-nav ul > li > span {
  display: block;
  color: var(--text-color);
  text-decoration: none;
  font-size: 1rem;       /* kleiner als vorher */
  font-weight: 500;      /* nicht mehr "bold" */
  padding: 8px 12px;
  border-radius: 8px;
  transition: background-color 0.2s, color 0.2s ease;
  cursor: pointer;

  /* kein default-Border-Bottom */
  border-bottom: none; 
}

/* HOVER => nur BG-Farbe + Text-Farbe */
.header_mobile-main-nav ul > li > a:hover,
.header_mobile-main-nav ul > li > span:hover {
  color: var(--primary-color);
  background-color: var(--secondary-bg-color);
  border-bottom: none; /* keinen Strich beim Hover */
}

/* AKTIVER LINK => farbige Unterstreichung wie am Desktop */
.header_mobile-main-nav a.active,
.header_mobile-main-nav span.active {
  color: var(--primary-color);
  font-weight: 600; /* etwas fetter als normal */
  background-color: transparent !important; /* keine BG-Farbe */
  border-bottom: 2px solid var(--primary-color); /* sichtbarer Strich */
}

.header_mobile-main-nav a.active:hover,
.header_mobile-main-nav span.active:hover {
  background-color: transparent;
  border-bottom: 2px solid var(--primary-color);
  color: var(--primary-color);
}

/* Submenü (Coinlist, Gainers, Tools usw.) */
.header_submenu-mobile a {
  /* analog anpassen */
  font-size: 1rem;
  font-weight: 500;
  padding: 8px 12px;
  border-radius: 6px;
}
.header_submenu-mobile a:hover {
  color: var(--primary-color);
  background-color: var(--secondary-bg-color);
}
.header_submenu-mobile a.active {
  color: var(--primary-color);
  font-weight: 600;
  background-color: var(--secondary-bg-color);
  border-bottom: 0;
}
.header_submenu-mobile {
  margin-left: 20px;
  border-left: 2px solid var(--secondary-bg-color);
  padding-left: 10px;
}

/* Ticker im Mobile-Menü */
.header_mobile-ticker {
  padding: 0; /* Kein Padding */
  border: none; /* Entfernt den Rahmen */
  border-radius: 0; /* Entfernt abgerundete Ecken */
  margin: 0; /* Kein Abstand zum restlichen Menü */
}

/* Mobile-Userlinks (Profil, Logout, Login, Register) */
.header_user-links-mobile {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  border-top: 1px solid var(--secondary-bg-color);
  padding-top: 10px;
}
.header_user-links-mobile a,
.header_user-links-mobile span {
  display: block;
  color: var(--text-color);
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: bold;
  padding: 8px 15px;
  border-radius: 8px;
  transition: background-color 0.2s, color 0.2s ease;
}
.header_user-links-mobile a:hover,
.header_user-links-mobile span:hover {
  color: var(--primary-color);
  background-color: var(--secondary-bg-color);
}

/* -----------------------------------------
   PRICE TICKER (allgemein)
------------------------------------------*/
.compact-price-ticker {
  position: relative;
  display: inline-block;
}
.coin-display {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  background-color: var(--background-color2);
  padding: 5px 8px;
  border: 1px solid var(--secondary-bg-color);
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  transition: background-color 0.2s ease;
}
.coin-display:hover {
  background-color: var(--secondary-bg-color);
}
.coin-display.price-up {
  animation: header_highlightPriceUp 0.5s;
}
.coin-display.price-down {
  animation: header_highlightPriceDown 0.5s;
}
@keyframes header_highlightPriceUp {
  0% { background-color: #6cff6c; }
  100% { background-color: transparent; }
}
@keyframes header_highlightPriceDown {
  0% { background-color: #ff6c6c; }
  100% { background-color: transparent; }
}
.coin-display-icon {
  width: 20px;
  height: 20px;
}
.coin-price-text {
  font-weight: 500;
  color: var(--text-color);
}
.dropdown-arrow {
  font-size: 0.8rem;
  opacity: 0.7;
}
.coin-dropdown {
  position: absolute;
  top: calc(100% + 6px);
  left: 0;
  background-color: var(--background-color2);
  border: 1px solid var(--secondary-bg-color);
  box-shadow: var(--box-shadow);
  border-radius: 6px;
  padding: 10px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 150px;
}
.dropdown-coin {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}
.dropdown-coin:hover {
  background-color: var(--secondary-bg-color);
}

.dropdown-coin-icon {
  width: 20px;
  height: 20px;
}

/* 
   Mobile (0–1199px)
*/
@media (max-width: 1199px) {
  .header_mobile-only {
    display: flex !important;
  }
  .header_desktop-only {
    display: none !important;
  }
  .header_price-ticker-container {
    display: none !important;
  }

  .header_btn {
    font-size: 1.1rem;
    padding: 0.3rem;
  }
  .header_btn:hover {
    transform: none !important;
    color: var(--primary-color);
  }
  .header_content {
    flex-wrap: nowrap;
    gap: 20px;
  }
}

/* 
   Desktop (ab 1200px)
*/
@media (min-width: 1200px) {
  .header_desktop-only {
    display: flex !important;
  }
  .header_mobile-only {
    display: none !important;
  }
  .header_price-ticker-container {
    display: block;
    margin-left: 125px; 
  }

  .header_nav ul {
    display: flex;
    gap: 20px;
    flex-wrap: nowrap;
    align-items: center;
  }
  .header_icon-group {
    gap: 10px;
  }

  .header_btn {
    font-size: 1.2rem;
    padding: 0.4rem;
    transition: transform 0.2s ease, color 0.2s ease;
  }
  .header_btn:hover {
    transform: scale(1.001);
  }
  .header_content {
    gap: 30px;
    flex-wrap: nowrap;
  }
}


.header_global-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px);
  z-index: 900; /* Hinter dem z.B. 9999 vom Overlay selbst, 
                   aber vor dem restlichen Content. 
                   Falls dein Dropdown-Panel z.B. z-index=9999 hat, 
                   kann das Overlay 900 haben. 
                   Oder andersherum: Je nach deiner Panel-Logik. 
                 */
}



.settings_dropdown-panel {
  position: absolute;
  top: calc(100% + 6px);
  right: 0;
  width: 220px;
  background-color: var(--background-color2);
  border: 1px solid var(--secondary-bg-color);
  box-shadow: var(--box-shadow);
  border-radius: 8px;
  padding: 10px;
  z-index: 9999;
}
.settings_dropdown-item {
  margin-bottom: 10px;
  font-size: 0.9rem;
}
.settings_dropdown-item label {
  display: block;
  font-weight: bold;
  margin-bottom: 3px;
}
.settings_dropdown-item select {
  width: 100%;
  padding: 5px;
  font-size: 0.9rem;
  border: 1px solid var(--secondary-bg-color);
  border-radius: 4px;
}
/* Button anpassen */
.settings_dropdown-panel button {
  padding: 6px 10px;
  font-weight: bold;
  border-radius: 4px;
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  cursor: pointer;
}

/* ==============================================
   SETTINGS-DROPDOWN => Auswahllisten
   ============================================== */
   .settings_dropdown-panel select {
    width: 100%;
    padding: 6px 8px;
    font-size: 0.9rem;
    border: 1px solid var(--secondary-bg-color);
    border-radius: 4px;
    background-color: var(--background-color);
    color: var(--text-color);
    background-image: none; 
    cursor: pointer;
    /* optional: Transition-Effekt bei Hover/Fokus */
    transition: border-color 0.15s ease, background-color 0.15s ease;
  }
  
  .settings_dropdown-panel select:hover {
    border-color: var(--primary-color);
  }
  
  .settings_dropdown-panel select:focus {
    outline: none;
    border-color: var(--primary-color);
  }

/* WebKit Scrollbar Styles (Chrome, Edge, Safari) */
.settings_dropdown-panel::-webkit-scrollbar {
  width: 8px;
}
.settings_dropdown-panel::-webkit-scrollbar-track {
  background: var(--background-color2);
  border-radius: 4px;
}
.settings_dropdown-panel::-webkit-scrollbar-thumb {
  background: var(--secondary-bg-color);
  border-radius: 4px;
}
.settings_dropdown-panel::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
}

/* WebKit Scrollbar Styles (Chrome, Edge, Safari) für Notifications */
.notifications_dropdown-panel::-webkit-scrollbar {
  width: 8px;
}

.notifications_dropdown-panel::-webkit-scrollbar-track {
  background: var(--background-color2);
  border-radius: 4px;
}

.notifications_dropdown-panel::-webkit-scrollbar-thumb {
  background: var(--secondary-bg-color);
  border-radius: 4px;
}

.notifications_dropdown-panel::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
}

/* ==============================================
   SCROLLBAR-STYLING (nur sichtbar, wenn select 
   -> multiple oder sehr lang mit Scroll)
   ============================================== */
/* WebKit Scrollbar Styles (Chrome, Edge, Safari) */
.settings_dropdown-panel select::-webkit-scrollbar {
  width: 8px;
}
.settings_dropdown-panel select::-webkit-scrollbar-track {
  background: var(--background-color2);
  border-radius: 4px;
}
.settings_dropdown-panel select::-webkit-scrollbar-thumb {
  background: var(--secondary-bg-color);
  border-radius: 4px;
}
.settings_dropdown-panel select::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
}

/* Card Hover-Effekt */
.search-card {
  cursor: pointer;
  transition: background-color 0.15s ease;
}
.search-card:hover {
  background-color: var(--secondary-bg-color);
}

/* Beispiel: Falls dein Such-Input eine Klasse .search_input hat: */
.search_input:hover {
  border-color: var(--primary-color);
}
.search_input:focus {
  outline: none;
  border-color: var(--primary-color);
}

/* 
   RESPONSIVE => Such-Panel auf kleinen Geräten max. 90%,
   ab z.B. 768px => 450px 
*/
@media (max-width: 768px) {
  .settings_dropdown-panel {
    width: 90% !important; /* statt fester 450px */
    max-width: 450px;
  }
  .notifications_dropdown-panel {
    width: 85% !important;
    max-width: 450px;
  }
}

@media (min-width: 768px) {
  .notifications_dropdown-panel {
    width: 30% !important;
    max-width: 350px;
  }
}


.badge-notification {
  position: absolute;
  top: -4px;
  right: -6px;
  background-color: red;
  color: white;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
}

