/**********************************/
/* Allgemeines Layout und Grundstil */
/**********************************/
.cbot-simulator {
  text-align: center;
  padding: 20px;
  background-color: var(--background-color);
  color: var(--text-color);
}

/* Optional: Wenn du h1 im gesamten cbot-simulator anders stylen willst */
.cbot-simulator h1 {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 20px;
}

/**********************************/
/* Panels und Spalten */
/**********************************/

/* 
   Übergeordnetes Layout-Container 
   mit ca. 3% Rand links und rechts 
*/
.cbot-simulator-layout {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  /* jeweils 3% pro Seite */
  margin-left: 3%;
  margin-right: 3%;
  width: 94%;

  min-height: 100vh;
  box-sizing: border-box;

  background: linear-gradient(
    135deg,
    var(--background-color) 0%,
    var(--background-color2) 100%
  );
  color: var(--text-color);

  padding-top: 40px; /* Platz nach oben */
}

/* Linke Spalte: 19% + 1% Margin rechts */
.cbot-left-panel {
  flex: 0 0 19%;
  margin-right: 1%;
  background-color: var(--background-color2);
  border: 2px solid var(--primary-color);
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  padding: 20px;
}

/* Mittlere Spalte: 60% */
.cbot-center-panel {
  flex: 0 0 60%;
  background-color: var(--background-color2);
  border: 2px solid var(--primary-color);
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  padding: 20px;
}

/* Ab hier extra Abstand oben für das H1 im Center-Panel */
.cbot-center-panel h1 {
  margin-top: 40px;
}

/* Rechte Spalte: 19% + 1% Margin links */
.cbot-right-panel {
  flex: 0 0 19%;
  margin-left: 1%;
  background-color: var(--background-color2);
  border: 2px solid var(--primary-color);
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  padding: 20px;
}

/**********************************/
/* Responsives Verhalten */
/**********************************/
@media (max-width: 900px) {
  .cbot-simulator-layout {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  .cbot-left-panel,
  .cbot-center-panel,
  .cbot-right-panel {
    flex: 1 1 auto;
    width: 100%;
    margin: 10px 0;
  }
}

/**********************************/
/* Chart */
/**********************************/
.cbot-chart-container {
  width: 100%;
  height: 400px;
  max-width: 800px;
  margin: 0 auto;
}

/**********************************/
/* Strategien / Form-Elemente */
/**********************************/
.cbot-strategy-selector-row {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
  flex-wrap: wrap;
}

.cbot-strategy-column {
  text-align: center;
  margin-bottom: 15px;
}

.cbot-strategy-column p {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 5px;
  color: var(--text-color);
}

.cbot-strategy-column select {
  padding: 10px;
  font-size: 1rem;
  border: 2px solid var(--primary-color);
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--background-color2);
  color: var(--text-color);
}

/* Parameter-Container für Zahlenfelder */
.cbot-strategy-parameter {
  margin-top: 10px;
}

.cbot-strategy-parameter p {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 1rem;
  color: var(--text-color);
}

.cbot-strategy-parameter input {
  padding: 5px;
  font-size: 1rem;
  width: 100px;
  border: 2px solid var(--primary-color);
  border-radius: 5px;
  background-color: var(--background-color2);
  color: var(--text-color);
}

/* Hover- und Focus-Effekte für Inputs und Selects */
.cbot-strategy-column select:hover,
.cbot-strategy-column select:focus,
.cbot-strategy-parameter input:hover,
.cbot-strategy-parameter input:focus {
  border-color: var(--price-up-color);
  outline: none;
}

/**********************************/
/* Bot-Steuerung / Buttons */
/**********************************/
.cbot-controls {
  margin: 20px auto;
}

.cbot-controls button {
  padding: 12px 25px;
  font-size: 1rem;
  margin: 10px;
  border: none;
  background-color: var(--btn-primary-bg);
  color: var(--btn-success-text, #ffffff);
  border-radius: var(--button-radius, 30px);
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;
}

.cbot-controls button:disabled {
  background-color: var(--secondary-bg-color);
  cursor: not-allowed;
}

.cbot-controls button:hover:not(:disabled) {
  background-color: var(--btn-primary-bg-hover);
  transform: scale(1.08);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

/**********************************/
/* Aktueller Preis */
/**********************************/
.cbot-current-price {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 10px 0;
  color: var(--text-color);
}

/**********************************/
/* Kontostand / Investment-Anzeige */
/**********************************/
.cbot-balance-investment-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
  flex-wrap: wrap;
}

.cbot-investment-section,
.cbot-balance-section {
  text-align: center;
}

.cbot-investment-section p,
.cbot-balance-section p {
  margin: 0;
  font-size: 1rem;
  color: var(--text-color);
}

.cbot-balance-display {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--text-color);
}

/**********************************/
/* Trades */
/**********************************/
.cbot-trades {
  margin: 20px auto;
  padding: 15px;
  background-color: var(--background-color2);
  border: 2px solid var(--primary-color);
  border-radius: 10px;
  max-width: 600px;
  text-align: left;
  box-shadow: var(--box-shadow);
}

.cbot-trades h3 {
  margin-top: 0;
  font-size: 1.8rem;
  color: var(--primary-color);
  margin-bottom: 10px;
}

.cbot-trades ul {
  list-style: none;
  padding: 0;
}

.cbot-trades li {
  margin: 5px 0;
  color: var(--text-color);
}

/* Buy/Sell Trades farblich hervorheben (optional) */
.cbot-trades li.buy {
  color: var(--price-up-color);
  font-weight: bold;
}
.cbot-trades li.sell {
  color: var(--price-down-color);
  font-weight: bold;
}

/**********************************/
/* Tooltip / Hinweise */
/**********************************/
.cbot-tooltip {
  margin: 20px auto;
  padding: 10px;
  background-color: var(--background-color2);
  border: 2px solid var(--primary-color);
  border-radius: 10px;
  text-align: center;
  max-width: 400px;
  box-shadow: var(--box-shadow);
  color: var(--text-color);
}

.cbot-immediate-sell-button {
  margin-top: 10px;
  padding: 12px 25px;
  font-size: 1rem;
  border: none;
  border-radius: var(--button-radius, 30px);
  cursor: pointer;
  font-weight: bold;
  transition: background 0.3s, transform 0.2s;
}

.cbot-immediate-sell-button:hover {
  transform: scale(1.05);
}

/**********************************/
/* Modal / Overlay */
/**********************************/
.cbot-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.cbot-modal-content {
  background: var(--background-color2);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: var(--box-shadow);
}

.cbot-modal-content p {
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: var(--text-color);
}

.cbot-redirect-button,
.cbot-close-button {
  padding: 12px 25px;
  font-size: 1rem;
  border: none;
  border-radius: var(--button-radius, 30px);
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;
  margin: 5px;
}

.cbot-redirect-button {
  background-color: var(--btn-primary-bg);
  color: var(--btn-success-text, #ffffff);
}

.cbot-redirect-button:hover {
  background-color: var(--btn-primary-bg-hover);
  transform: scale(1.05);
}

.cbot-close-button {
  background-color: var(--btn-secondary-bg);
  color: var(--btn-success-text, #ffffff);
}

.cbot-close-button:hover {
  background-color: var(--btn-secondary-bg-hover);
  transform: scale(1.05);
}

/**********************************/
/* Symbolauswahl */
/**********************************/
.cbot-symbol-selection {
  margin: 20px 0;
}

.cbot-symbol-selection p {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 5px;
  color: var(--text-color);
}

.cbot-symbol-selection select {
  padding: 10px;
  font-size: 1rem;
  border: 2px solid var(--primary-color);
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--background-color2);
  color: var(--text-color);
}

/**********************************/
/* Back-Button */
/**********************************/
.cbot-back-button {
  margin-top: 20px;
  background-color: var(--btn-primary-bg);
  color: var(--btn-success-text, #ffffff);
  padding: 12px 25px;
  font-size: 1rem;
  border: none;
  border-radius: var(--button-radius, 30px);
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;
}

.cbot-back-button:hover {
  background-color: var(--btn-primary-bg-hover);
  transform: scale(1.05);
}
