/* widget-dashboard-container */
.widget-dashboard-container {
    position: relative; /* NEU: damit .widget-dashboard-new-icon sich relativ dazu positioniert */
    /* dein bisheriger Code: width, max-width, ... */
    width: 90%;
    max-width: 1200px;
    background-color: transparent;
    border-radius: 20px;
    box-shadow: var(--box-shadow);
    margin: 40px auto;
    padding: 20px;
    border: 0.5px solid var(--secondary-bg-color);
    color: var(--text-color);
  }

.widget-dashboard-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.6rem;
  color: var(--primary-color);
}

.widget-dashboard-actions {
  margin-bottom: 20px;
  text-align: center;
}

.widget-create-button {
  background-color: var(--btn-primary-bg);
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  cursor: pointer;
  text-decoration: none;
}
.widget-create-button:hover {
  background-color: var(--btn-primary-bg-hover);
}

.widget-guest-info {
  margin-bottom: 20px;
  color: var(--text-color);
  font-size: 1rem;
  text-align: center;
  border: 0.5px solid var(--secondary-bg-color);
  padding: 10px;
  border-radius: 10px;
  background-color: transparent;
}

.widget-error-message {
  text-align: center;
  color: var(--price-down-color);
  margin-bottom: 20px;
}

.widget-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.widget-card {
  width: 300px;
  background-color: transparent;
  border: 0.5px solid var(--secondary-bg-color);
  border-radius: 10px;
  padding: 15px;
  box-shadow: var(--box-shadow);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.widget-card-header h3 {
  margin: 0 0 10px 0;
  color: var(--text-color);
  font-size: 1.2rem;
}

.widget-dates-info {
  font-size: 0.8rem;
  color: var(--text-color);
  opacity: 0.8;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

/* Frame-Container */
.widget-preview-frame {
  margin: 10px 0;
  text-align: center;
}

/* Wichtig: kein Rand & Overflow hidden im iFrame selbst */
.widget-preview-frame iframe {
  display: block;
  margin: 0 auto;
  border: none;       /* Entfernt den extra Rahmen */
  overflow: hidden;   /* Kein Scrollbalken */
}

.widget-card-actions {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.widget-edit-button,
.widget-delete-button,
.widget-embed-copy-button {
  background-color: var(--btn-primary-bg);
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 6px 10px;
  cursor: pointer;
  font-size: 0.9rem;
}

.widget-delete-button {
  background-color: var(--price-down-color);
}

.widget-edit-button:hover {
  background-color: var(--btn-primary-bg-hover);
}
.widget-delete-button:hover {
  background-color: #ff6666;
}

.widget-embed-codes {
  display: flex;
  gap: 15px;          /* oder so viel Abstand du möchtest */
  justify-content: center; /* zentriert die Buttons als Gruppe */
}

.widget-embed-copy-button:hover {
  background-color: var(--btn-primary-bg-hover);
}

/* Responsive */
@media (max-width: 768px) {
  .widget-dashboard-container {
    width: 95%;
  }
  .widget-cards-container {
    flex-direction: column;
    align-items: center;
  }
  .widget-card {
    width: 100%;
    max-width: 400px;
  }
}




.widget-card-header {
    position: relative; /* damit die Icons dort positioniert werden können */
  }
  
  /* Der Container für die Icons oben rechts */
  .widget-card-icons {
    position: absolute;
    top: 0px;
    right: 0px;
    display: flex;
    gap: 8px;
  }

  /*  Neuer Container => Icon oben rechts */
.widget-dashboard-new-icon {
    position: absolute;
    top: 25px;
    right: 12px;
  }
  
 /* 1) Appearance wegnehmen (Chrome, Safari) */
.widget-icon-btn {
    -webkit-appearance: none;
    appearance: none;
    background: none;
    border: none;
    color: var(--btn-primary-bg);
    font-size: 1.2rem;
    cursor: pointer;
    padding: 0;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
  
    -webkit-tap-highlight-color: transparent; /* Mobile Safari */
  }
  
  /* 2) Universal: kein Outline / Box-Shadow, egal welcher State */
  .widget-icon-btn:hover,
  .widget-icon-btn:focus,
  .widget-icon-btn:active {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    background: none !important;
  }
  
  /* 3) Firefox: Entfernt das innere "Focus-Dotted" */
  .widget-icon-btn::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  
  /* 4) Falls Browser :focus-visible unterstützt, ebenfalls weg */
  .widget-icon-btn:focus-visible {
    outline: none !important;
    box-shadow: none !important;
  }
  
  /* 5) Optionaler Hover-Effekt => Farbe leicht ändern oder Scale */
  .widget-icon-btn:hover {
    color: var(--btn-primary-bg-hover);
    transform: scale(1.1);
  }
  
  /* 6) Spezielle Variante für Lösch-Icon => Rot */
  .widget-icon-btn.delete {
    color: var(--price-down-color);
  }
  .widget-icon-btn.delete:hover {
    color: #ff6666;
  }


/* Wenn du nur das Icon selbst größer machen willst: */
.widget-icon-btn.new-widget svg {
    font-size: 1.5rem;
  }



  .widget-embed-overlay {
    position: fixed;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .widget-embed-modal {
    background-color: var(--background-color2);
    color: var(--text-color);
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
  }