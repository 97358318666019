/* src/components/dashboard/Dashboard.css */

/* =========================================
   Hauptcontainer (Desktop)
========================================= */
#dashboard-container {
    width: 90%;
    max-width: 1200px;
    margin: 20px auto;
    padding: 10px;
    background-color: transparent;
    color: var(--text-color);
}

/* Container für die Dashboard Controls */
.dashboard-controls {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    position: relative; /* Damit der Tooltip relativ zum Container positioniert wird */
}

/* Spezifisches Styling für das Lock-Tooltip */
.lock-tooltip {
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 0.8rem;
    position: absolute;
    top: 110%;
    right: 0;
    white-space: nowrap; /* Standard: keine Zeilenumbrüche */
    margin-top: 5px;
    z-index: 100;
}

#dashboard-container h2 {
    text-align: center;
    color: var(--primary-color);
    margin-bottom: 10px;
}

#dashboard-container p {
    text-align: center;
    max-width: 700px;
    margin: 0 auto 20px;
}

/* =========================================
   Grid-Layout für Widgets
========================================= */
.dashboard-widgets {
    margin-top: 20px;
}

/* =========================================
   Base-Widget
========================================= */
.dashboard-widget {
    background-color: transparent;
    border-radius: 8px;
    border: 0.5px solid var(--secondary-bg-color);
    box-shadow: var(--box-shadow);
    padding: 15px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.dashboard-widget-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.dashboard-widget-title {
    font-size: 1.1rem;
    font-weight: bold;
    margin: 0;
}

.dashboard-widget-icons {
    display: flex;
    gap: 8px;
}

.dashboard-widget-icon-btn {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: var(--primary-color);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    cursor: pointer;
    transition: opacity 0.2s ease;
}
.dashboard-widget-icon-btn:hover {
    opacity: 0.8;
}

/* =========================================
   Größenvarianten
========================================= */


.dashboard-widget-small,
.dashboard-widget-medium,
.dashboard-widget-large {
  /* Ggf. nur Hintergrund/Farben, aber KEINE min-width */
  min-width: 0; 
  width: 100%;
  /* oder was auch immer, nur keine Restriktionen */
}

/* Minimale Reduzierung bei sehr schmalen Viewports */
@media (max-width: 480px) {
    .dashboard-widget-large {
        min-width: 280px;
    }
}

/* =========================================
   Modal-Styles
========================================= */
.dashboard-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.dashboard-modal-content {
    background-color: var(--secondary-bg-color);
    padding: 20px;
    border-radius: 8px;
    max-width: 90%;
    max-height: 90%;
    overflow-y: auto;
    position: relative;
}

.dashboard-modal-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    left: auto;
    z-index: 10;
}

/* =========================================
   Responsive (Mobile)
========================================= */
@media (max-width: 480px) {

    /* Container füllt die ganze Breite */
    #dashboard-container {
        width: 100%;
        margin: 10px auto;
        padding: 10px; /* falls du’s kompakter willst */
    }

    .dashboard-controls button {
        display: inline-flex;
        width: auto;
        padding: 4px 8px; /* Passe diese Werte nach Bedarf an */
        margin: 0;
        align-items: center;
        justify-content: center;
      }

      .lock-tooltip {
        left: 50%;
        right: auto;
        transform: translateX(-50%);
        max-width: 90vw; /* Begrenze die Breite, sodass der Text umbricht */
        white-space: normal; /* Erlaubt Zeilenumbrüche */
      }

    /* Jedes Widget ist 95% breit, zentriert */
    .dashboard-widget {
        margin-bottom: 15px;
        padding: 10px; 
    }

    /* min-width aufheben, damit die Cards nicht zu breit werden */
    .dashboard-widget-small,
    .dashboard-widget-medium,
    .dashboard-widget-large {
        flex: none;
        min-width: unset;
    }

    .dashboard-widget h4 {
        font-size: 1.15rem;
      }

      .dashboard-widget p {
        font-size: 0.9rem;
      }

      

    .dashboard-widget-icon-btn {
        width: 20px;
        height: 20px;
        font-size: 12px;
    }

    /* Modal-Content */
    .dashboard-modal-content {
        padding: 10px 7px 0 0;
        max-width: 97%;
        max-height: 97%;
    }
    .dashboard-modal-close-btn {
        top: 10px;
        right: 5px;
        width: 28px;
        height: 28px;
        font-size: 1.3rem;
    }
}

/* ========================================
   NewlyListedCoinsMini
======================================== */
.newly-listed-coins-mini-widget {
    margin-top: 5px;
}

.newly-listed-coins-mini-error {
    color: var(--price-down-color);
}

.newly-listed-coins-mini-table {
    width: 100%;
    border-collapse: collapse;
}

.newly-listed-coins-mini-table th,
.newly-listed-coins-mini-table td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid var(--secondary-bg-color);
    vertical-align: middle;
    line-height: 1.2;
    transition: color 0.2s ease; /* Hover */
}

.mini-desktop-only {
    /* 24h-Spalte: standard sichtbar */
}

.mini-mobile-24h-change {
    display: none; /* Desktop unsichtbar */
}

.newly-listed-coins-mini-table thead th {
    font-weight: bold;
    font-size: 1rem;
}

/* Ganze Zeile klickbar */
.clickable-row {
    cursor: pointer;
}
.clickable-row:hover {
    background-color: var(--secondary-bg-color);
    color: var(--primary-color);
}

/* price-up / price-down Farben */
.price-up {
    color: var(--price-up-color);
}
.price-down {
    color: var(--price-down-color);
}

/* Responsive */
@media (max-width: 480px) {
    .newly-listed-coins-mini-table th,
    .newly-listed-coins-mini-table td {
        padding: 6px;
        font-size: 0.9rem;
    }

    /* 24h-Spalte ausblenden */
    .mini-desktop-only {
        display: none;
    }

    .mini-mobile-24h-change {
        display: block;
        font-size: 0.9rem;
        margin-top: 3px;
    }
}

/* =====================================
   Top10DominanceMini – kleines Widget
===================================== */
.top10-dominance-mini-widget {
    margin-top: 5px;
    text-align: center;
}

.top10-dominance-mini-error {
    color: var(--price-down-color);
}

.top10-dominance-mini-widget .top10-dominance-chart-container {
    width: 100%;
    max-width: 400px;
    margin: 20px auto;
    position: relative;
}





/* =====================================
   GainersLosersMini – kleines Widget
===================================== */
.gainers-losers-mini-widget {
    width: 100%;
    height: 100%;
    background-color: transparent;
    color: var(--text-color);
    text-align: center;
    margin-top: 5px; /* optional, wie 'newly-listed-coins-mini-widget' */
}

.gainers-losers-mini-error {
    color: var(--price-down-color);
}

/* Tab-Schalter oben */
.gainers-losers-mini-tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 0.5rem;
    gap: 8px;
}

.gainers-losers-mini-tabs button {
    background: var(--secondary-bg-color);
    color: var(--text-color);
    border: none;
    padding: 6px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.gainers-losers-mini-tabs button.gainers-losers-mini-active {
    background: var(--primary-color);
    color: #fff;
}

/* Tabelle */
.gainers-losers-mini-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
}

.gainers-losers-mini-table thead th {
    font-weight: bold;
    font-size: 1rem;
}

.gainers-losers-mini-table th,
.gainers-losers-mini-table td {
    border-bottom: 1px solid var(--secondary-bg-color);
    padding: 8px;
    font-size: 0.95rem;
    vertical-align: middle;
    white-space: nowrap;
    line-height: 1.2;
    transition: color 0.2s ease;
}

/* Desktop-Spalte für 24h */
.gainers-losers-mini-table .mini-desktop-only {
    /* 24h-Spalte: standard sichtbar am Desktop */
}

/* Mobile-Anzeige der 24h-Änderung (hidden am Desktop) */
.gainers-losers-mini-table .mini-mobile-24h-change {
    display: none;
    font-size: 0.9rem;
    margin-top: 3px;
}

/* Ganze Zeile klickbar */
.gainers-losers-mini-clickable-row {
    cursor: pointer;
}
.gainers-losers-mini-clickable-row:hover {
    background-color: var(--secondary-bg-color);
    color: var(--primary-color);
}

/* price-up / price-down Farben */
.price-up {
    color: var(--price-up-color);
}
.price-down {
    color: var(--price-down-color);
}

/* =====================================
   Responsive (Mobile) für GainersLosersMini
===================================== */
@media (max-width: 480px) {
    .gainers-losers-mini-table th,
    .gainers-losers-mini-table td {
        padding: 6px;
        font-size: 0.9rem;
    }
    /* 24h-Spalte ausblenden */
    .gainers-losers-mini-table .mini-desktop-only {
        display: none;
    }
    /* Mobile: unter dem Preis anzeigen */
    .gainers-losers-mini-table .mini-mobile-24h-change {
        display: block;
    }
}