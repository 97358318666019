/* src/styles/UserFavoritesManager.css */

/* Haupt-Container */
.favorites-manager-container {
  width: 90%;
  max-width: 1200px;
  background-color: transparent;
  border-radius: 20px;
  box-shadow: var(--box-shadow);
  margin: 40px auto;
  padding: 20px;
  color: var(--text-color);
  border: 0px solid var(--secondary-bg-color);
}

.favorites-manager-container h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
  color: var(--primary-color);
}

/* Loading-Hinweis */
.loading-hint {
  text-align: center;
  margin: 20px 0;
  font-size: 1.1rem;
  font-weight: bold;
  color: var(--text-color);
}

/* Ordner-Karten */
.favorites-card {
  background-color: transparent;
  border: 0px solid var(--secondary-bg-color);
  border-radius: 10px;
  margin-bottom: 30px;
  box-shadow: var(--box-shadow);
}

.favorites-card-header {
  background: transparent;
  padding: 0 0 10px 0;
  border-bottom: 1px solid var(--secondary-bg-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.favorites-card-header h3 {
  margin: 0;
  font-size: 1.3rem;
  color: var(--primary-color);
}

.favorites-card-body {
  padding: 10px 0;
}

.folder-header-actions {
  display: flex;
  gap: 8px;
}

/* Leere Hinweise */
.empty-hint {
  font-style: italic;
  color: var(--text-color);
}

/* Tabellen-Layout */
.favorites-coin-table {
  width: 100%;
  border-collapse: collapse;
}

.favorites-coin-table th,
.favorites-coin-table td {
  text-align: left;
  padding: 10px;
  border-bottom: 1px solid var(--secondary-bg-color);
  vertical-align: middle;
  line-height: 1.2;
}

.favorites-coin-table th {
  font-weight: bold;
  cursor: default;
  white-space: nowrap;
}

/* Hover-Effekt */
.favorites-coin-table tbody tr:hover {
  background-color: var(--secondary-bg-color);
}

/* Namenszelle: Logo + Coinname links, kein data-label (mobile) */
.coin-info-cell {
  gap: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.coin-info-cell img,
.coin-info-cell .coin-name-favs,
.coin-info-cell .coin-symbol {
  vertical-align: middle;
}

.coin-logo {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
}

.coin-name-favs {
  font-weight: bold;
  color: var(--text-color);
  margin-right: 5px;
}


.coin-symbol {
  opacity: 0.7;
}

/* rename-form */
.rename-form {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Highlighting (analog CoinList) */
.highlightText-up {
  animation: highlightTextGreen 0.5s ease-in-out;
}
.highlightText-down {
  animation: highlightTextRed 0.5s ease-in-out;
}

@keyframes highlightTextGreen {
  0% { color: #39d353; }
  50% { color: #2ea043; }
  100% { color: inherit; }
}
@keyframes highlightTextRed {
  0% { color: #f85149; }
  50% { color: #da3633; }
  100% { color: inherit; }
}

@media (max-width: 768px) {
  .favorites-manager-container {
    width: 95%;
    overflow-x: hidden;
    margin: 20px auto;
    padding: 10px;
  }
  .favorites-card {
    box-shadow: none;
    border: 0.5px solid var(--secondary-bg-color);
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 10px;
  }
  .favorites-card-header {
    margin-bottom: 7px;
    padding: 0;
    border-bottom: 0px solid var(--secondary-bg-color);
    position: relative;
  }
  /* Kopfzeile der Tabelle ausblenden */
  .favorites-coin-table thead {
    display: none;
  }
  .favorites-coin-table tbody {
    display: block;
    width: 100%;
  }
  /* Jede Tabellenzeile als Grid */
  .favorites-coin-table tr {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-auto-rows: auto;
    grid-gap: 8px;
    position: relative;
    margin-bottom: 20px;
    border: 0.5px solid var(--secondary-bg-color);
    border-radius: 10px;
    padding: 10px;
    background-color: transparent;
    overflow: hidden;
  }
  /* Alle direkten td nehmen 100% Breite */
  .favorites-coin-table tr > td {
    width: 100%;
  }
  /* Platziere die coin-info-cell (Logo, Titel, Symbol) in der ersten Spalte, erste Zeile */
  .favorites-coin-table tr > td.coin-info-cell {
    grid-column: 1 / 2;
    grid-row: 1;
    justify-self: start;
  }
  /* Platziere die Löschzelle in der ersten Zeile, zweite Spalte */
  .favorites-coin-table tr > td.mobile-delete-cell {
    grid-column: 2 / 3;
    grid-row: 1;
    justify-self: end;
    align-self: start; /* Icons oben ausrichten */
    display: flex;
    gap: 1px;
  }

  /* Alle übrigen Zellen (z. B. Rank, Preis, 24h %, etc.) nehmen die volle Zeile ein und richten den Inhalt rechts aus */
  .favorites-coin-table tr > td:not(.coin-info-cell):not(.mobile-delete-cell) {
    grid-column: 1 / -1;
    text-align: right;
    overflow: hidden;
  }
  /* Für diese übrigen Zellen: Das data-label soll links (per float) angezeigt werden */
  .favorites-coin-table tr > td:not(.coin-info-cell):not(.mobile-delete-cell):before {
    content: attr(data-label);
    display: inline-block;
    width: 50%; /* ggf. anpassen */
    float: left;
    text-align: left;
    font-weight: bold;
    margin-right: 10px;
    color: var(--primary-color);
  }
  /* In coin-info- und mobile-delete-Zellen soll kein Label erscheinen */
  .favorites-coin-table tr > td.coin-info-cell:before,
  .favorites-coin-table tr > td.mobile-delete-cell:before {
    content: none;
  }

  .folder-title-favs {
    max-width: 16ch;        /* Maximal 80 Zeichen breit */
    white-space: nowrap;    /* Kein Zeilenumbruch */
    overflow: hidden;       /* Überlauf verstecken */
    text-overflow: ellipsis;/* Ellipsis einfügen */
  }

  .coin-name-favs {
    max-width: 16ch;        /* Maximal 80 Zeichen breit */
    white-space: nowrap;    /* Kein Zeilenumbruch */
    overflow: hidden;       /* Überlauf verstecken */
    text-overflow: ellipsis;/* Ellipsis einfügen */
  }
}



.comparison-row {
  background-color: var(--secondary-bg-color);
  transition: all 0.3s ease;
}

.comparison-container {
  padding: 10px;
  font-size: 0.9rem;
  color: var(--text-color);
}

.comparison-label {
  font-weight: bold;
  color: var(--primary-color);
}




/* Mobile Vergleichszeile – ganz in einer Zelle */
.comparison-row-mobile td {
  padding: 5px 10px;
  background-color: var(--secondary-bg-color);
}

/* Vergleichselemente in der mobilen Ansicht */
.comparison-item {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--secondary-bg-color);
  padding: 4px 0;
}

.comparison-item:last-child {
  border-bottom: none;
}

.comparison-item-label {
  /* Hier ggf. noch ein Label einfügen, wenn nötig – aktuell leer */
  width: 50%;
}

.comparison-item-value {
  width: 50%;
  text-align: right;
}



/* Container für mobilen Zellinhalt */
.mobile-favs-cell-content {
  display: flex;
  flex-direction: column;
}

/* Aktueller Wert in der mobilen Zelle */
.mobile-favs-current-value {
  /* Hier kannst du z.B. die Schriftgröße oder Farbe festlegen */
}

/* Alter Vergleichswert in der mobilen Zelle */
.mobile-favs-old-value {
  font-size: 0.8rem;
  color: var(--secondary-text-color);
  margin-top: 2px;
}

/* Neue Zeile für das Vergleichsdatum – soll oberhalb der Hauptzeile erscheinen */
.mobile-created-at-row td {
  padding: 5px 10px;
  background-color: var(--secondary-bg-color);
}

.mobile-created-at-label {
  font-size: 0.7rem;
  color: var(--secondary-text-color);
  text-align: left;
}

/* Sicherstellen, dass die Aktionszelle oben bleibt */
.favorites-coin-table tr > td.mobile-delete-cell {
  grid-column: 2 / 3;
  grid-row: 1;
  justify-self: end;
  align-self: start;
  display: flex;
  gap: 1px;
}