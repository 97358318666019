/* src/styles/AdminUserManagement.css */

/* Container ... */
.adminusermanagement-container {
  width: 90%;
  max-width: 1200px;
  margin: 40px auto;
  padding: 20px;
  background-color: var(--background-color2);
  border-radius: 20px;
  box-shadow: var(--box-shadow);
  border: 1px solid var(--secondary-bg-color);
  color: var(--text-color);
}

.adminusermanagement-container h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
  color: var(--primary-color);
}

/* Tabelle */
.adminusermanagement-table {
  width: 100%;
  border-collapse: collapse;
}

.adminusermanagement-table th,
.adminusermanagement-table td {
  padding: 12px;
  border-bottom: 1px solid var(--secondary-bg-color);
}

.adminusermanagement-table thead {
  background-color: var(--background-color);
}

.adminusermanagement-table tbody tr:hover {
  background-color: var(--background-color-hover);
}

/* Icon-/Action-Buttons */
.btn-action {
  background: none;
  border: none;
  outline: none;
  color: var(--text-color);
  font-size: 1.2rem;
  padding: 0;
  margin: 0 8px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.btn-action:hover,
.btn-action:focus {
  outline: none;
  box-shadow: none;
}

/* Pagination-Container */
.adminusermanagement-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 12px;
}

/* Buttons */
.adminusermanagement-pagination-button {
  background-color: var(--background-color2);
  color: var(--text-color);
  border: 1px solid var(--secondary-bg-color);
  border-radius: 6px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.adminusermanagement-pagination-button:hover:enabled {
  background-color: var(--background-color-hover);
}

.adminusermanagement-pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Page status "Seite X von Y" */
.adminusermanagement-pagination-status {
  font-weight: bold;
}

/* Modal Overlay */
.adminusermanagement-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.adminusermanagement-modal-content {
  background-color: var(--background-color2);
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  color: var(--text-color);
  box-shadow: var(--box-shadow);
  text-align: center;
}

/* Modal-Buttons */
.modal-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.modal-buttons button {
  padding: 10px 20px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}

/* Zusätzliche Styles für das Submenü */
.admin-submenu {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  justify-content: center;
  /* oder flex-start/flex-end */
}

.admin-submenu-link {
  text-decoration: none;
  padding: 8px 16px;
  border-radius: 6px;
  border: 1px solid var(--secondary-bg-color);
  background-color: var(--background-color2);
  color: var(--text-color);
  transition: background-color 0.2s ease;
}

/* Hover-Effekt */
.admin-submenu-link:hover {
  background-color: var(--background-color-hover);
}

/* Aktiv-Link abheben */
.admin-submenu-link.active {
  background-color: var(--primary-color);
  color: #fff;
  border-color: var(--primary-color);
}



.adminusermanagement-user-email {
  font-size: 0.85rem;
  color: #999;
  margin-left: 28px; /* optional: damit es unter dem Namen einrückt */
  display: block;    /* auf eigener Zeile */
  margin-top: 2px;
}


.adminusermanagement-topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}



/* Suche + Dropdown + Button in kleiner Box */
.tickets-filter-container {
  display: flex;
  justify-content: center; /* zentriert oder flext-start, wie du willst */
  margin-bottom: 20px;
}

.tickets-filter-form {
  display: inline-flex; 
  align-items: center;
  gap: 8px;
  background-color: var(--background-color-hover);
  padding: 10px 14px;
  border-radius: 8px;
  /* max-width, optional: */
  max-width: 500px;
  /* 
  border: 1px solid var(--secondary-bg-color);
  */
}

.tickets-filter-form input,
.tickets-filter-form select {
  /* Bisschen Stylen */
  padding: 6px 8px;
  border: 1px solid var(--secondary-bg-color);
  border-radius: 4px;
  background-color: var(--background-color2);
  color: var(--text-color);
}