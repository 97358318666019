/* src/styles/WidgetBuilder.css */

.widget-builder-container {
  width: 90%;
  max-width: 1200px;
  margin: 40px auto;
  padding: 20px;
  background-color: transparent;
  border: 0.5px solid var(--secondary-bg-color);
  border-radius: 20px;
  box-shadow: var(--box-shadow);
  color: var(--text-color);
}

.widget-builder-title {
  text-align: center;
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: var(--primary-color);
}

.widget-builder-error {
  color: var(--price-down-color);
  text-align: center;
  margin-bottom: 20px;
}

.widget-builder-form {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

/* Linke Seite: Felder */
.widget-builder-fields {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.widget-field {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.widget-field label {
  font-weight: bold;
  color: var(--text-color);
}

/* Angepasster Style für Textfelder, Selects und Farbfelder – angelehnt an SingleSelectNoSearch.jsx */
.widget-field input[type="text"],
.widget-field select,
.widget-field input[type="color"] {
  padding: 8px;
  border: 0.5px solid var(--primary-color);  /* Border wie im SingleSelect */
  border-radius: 4px;                          /* Anpassen: 4px statt 8px */
  background-color: transparent;
  color: var(--text-color);
  font-size: 0.8rem;                           /* Anpassen: 0.8rem statt 0.95rem */
  min-height: 30px;                           /* Analog zur minHeight im SingleSelect */
  cursor: pointer;
  transition: box-shadow 0.2s ease, border 0.2s ease;
  outline: none;
}

/* Hover- und Focus-Effekte, analog zum React-Select */
.widget-field input[type="text"]:hover,
.widget-field select:hover,
.widget-field input[type="color"]:hover,
.widget-field input[type="text"]:focus,
.widget-field select:focus,
.widget-field input[type="color"]:focus {
  box-shadow: 0 0 4px var(--primary-color);
  outline: none;
}

/* Falls wir 3+ Felder in einer Zeile wollen */
.widget-field-inline {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-end;
}

.color-field {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

/* Rechte Seite: Preview */
.widget-builder-preview {
  flex: 1;
  background-color: transparent;
  border: 0.5px solid var(--secondary-bg-color);
  border-radius: 10px;
  padding: 15px;
  text-align: center;
}

.widget-builder-preview h3 {
  margin-top: 0;
  margin-bottom: 10px;
  color: var(--text-color);
}

.widget-preview-box {
  width: 240px;          /* Fest auf 240px */
  height: 160px;         /* Fest auf 160px */
  margin: 10px auto;     /* Zentriert die Box horizontal */
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  margin-top: 10px;
  color: #222;
}

/* Gemeinsamer Basisklassen-Stil für Buttons */
.widget-builder-button {
  background-color: transparent;
  color: var(--text-color);
  border: 0.5px solid var(--text-color);
  border-radius: 8px;
  padding: 6px 10px;
  cursor: pointer;
  font-size: 1.0rem;
}

.widget-builder-button:hover {
  background-color: transparent;
}

/* (Optional) Abbrechen-Button, falls du eine andere Farbe willst */
.widget-builder-button.cancel {
  background-color: var(--btn-secondary-bg);
}

.widget-builder-button.cancel:hover {
  background-color: var(--btn-secondary-bg-hover);
}

/* Responsive */
@media (max-width: 768px) {
  .widget-builder-form {
    flex-direction: column;
  }
  .widget-field-inline {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }
}
