.what-is-crypto {
  width: 90%;
  max-width: 800px;
  background-color: var(--background-color2); /* Hintergrund analog zu anderen Containern */
  border-radius: 20px;
  box-shadow: var(--box-shadow);
  margin: 40px auto; /* Gleicher Abstand wie bei anderen Containern */
  padding: 30px 20px;
  color: var(--text-color);
  border: 1px solid var(--secondary-bg-color); /* Rahmen wie bei den anderen Containern */
}

/* Übersichtskarte Inhalt */
.overview-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

/* Grafik-Styling */
.crypto-graphic {
  width: 150px;
  height: 150px;
}

/* Textinhalt in der Übersicht */
.overview-text {
  flex: 1;
  text-align: left;
}

.overview-text h2 {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 10px;
}

.overview-text p {
  font-size: 1.1rem;
  color: var(--text-color);
  line-height: 1.6;
}

/* Kategorien */
.categories {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

.category-item {
  background-color: var(--secondary-bg-color); 
  padding: 10px 15px;
  border-radius: var(--button-radius, 10px);
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  font-size: 1.2rem;
  color: var(--text-color);
}

.category-item:hover {
  background-color: var(--btn-primary-bg-hover); 
  color: var(--btn-success-text, white);
  transform: scale(1.05);
}

.simulator-navigation {
  display: flex;
  justify-content: center; /* statt space-between */
  margin-top: 20px;
  gap: 20px; /* optional: fügt Abstand zwischen den Buttons hinzu */
}

/* Responsive Design */
@media (max-width: 768px) {
  .overview-content {
    flex-direction: column;
    text-align: center;
  }

  .overview-text {
    text-align: center;
  }

  .crypto-graphic {
    width: 120px;
    height: 120px;
  }

  .categories {
    flex-direction: column;
    align-items: center;
  }
}

/* Popover Anpassungen */
.popover {
  max-width: 300px;
  background-color: var(--background-color2); /* Hintergrund anpassen wie bei anderen Containern */
  color: var(--text-color);
  border: 1px solid var(--secondary-bg-color);
  box-shadow: var(--box-shadow);
}

.popover-header {
  background-color: var(--primary-color);
  color: var(--btn-success-text, white);
  font-weight: bold;
  padding: 10px 15px;
  border-bottom: 1px solid var(--secondary-bg-color);
  border-radius: 10px 10px 0 0;
}

.popover-body {
  color: var(--text-color);
  padding: 15px;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0 0 10px 10px;
}

.popover-arrow {
  border-color: var(--background-color2);
}