/* src/styles/FuturesExchangeList.css */

* {
    box-sizing: border-box;
  }
  
  .futures-exchange-list-container {
    width: 90%;
    max-width: 1200px;
    background-color: transparent;
    border-radius: 20px;
    box-shadow: var(--box-shadow);
    margin: 40px auto;
    padding: 20px;
    color: var(--text-color);
    border: 0px solid var(--secondary-bg-color);
  }
  
  .futures-exchange-list-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
    color: var(--primary-color);
  }
  
  .futures-exchange-list-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    gap: 20px;
  }
  
  .futures-per-page label {
    margin-right: 10px;
  }
  
  .futures-per-page select {
    padding: 5px 10px;
    border-radius: 10px;
    border: 1px solid var(--secondary-bg-color);
    background-color: var(--secondary-bg-color);
    color: var(--text-color);
  }
  
  .pagination-controls button {
    background-color: var(--btn-primary-bg);
    color: #fff;
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .pagination-controls button:hover:not(:disabled) {
    background-color: var(--btn-primary-bg-hover);
  }
  
  .pagination-controls button:disabled {
    background-color: var(--btn-secondary-bg);
    cursor: not-allowed;
  }
  
  .pagination-controls span {
    margin: 0 10px;
    color: var(--text-color);
  }
  
  .futures-exchange-list-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .futures-exchange-list-table th,
  .futures-exchange-list-table td {
    text-align: left;
    padding: 10px;
    border-bottom: 1px solid var(--secondary-bg-color);
    vertical-align: middle;
    line-height: 1.2;
  }
  
  .futures-exchange-list-table th {
    font-weight: bold;
    cursor: default;
  }
  
  .futures-exchange-list-table tbody tr:hover {
    background-color: var(--secondary-bg-color);
    cursor: pointer;
  }
  
  /* Anpassungen für die Info-Zelle */
  .futures-info-cell {
    gap: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .futures-info-cell .futures-market,
  .futures-info-cell .futures-symbol {
    vertical-align: middle;
  }
  
  .futures-market,
  .futures-symbol {
    display: inline-block;
    vertical-align: middle;
    line-height: 1.2;
  }
  
  .futures-market {
    font-weight: bold;
    color: var(--text-color);
    margin-right: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .loading,
  .error-message {
    text-align: center;
    margin: 20px 0;
    font-size: 1.1rem;
    font-weight: bold;
    color: var(--text-color);
  }
  
  .bottom-pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 10px;
  }
  
  /* Responsive Anpassungen */
  @media (max-width: 768px) {
    .futures-exchange-list-container {
      width: 95%;
      max-width: 1200px;
      background-color: transparent;
      border-radius: 20px;
      box-shadow: var(--box-shadow);
      margin: 40px auto;
      padding: 10px;
      color: var(--text-color);
      border: 0.5px solid var(--secondary-bg-color);
      overflow-x: hidden;
    }
  
    .futures-exchange-list-title {
      font-size: 1.5rem;
      margin-top: 5px;
    }
  
    .futures-exchange-list-controls {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }
  
    .futures-exchange-list-table thead {
      display: none;
    }
  
    .futures-exchange-list-table tbody {
      display: block;
      width: 100%;
      overflow-x: hidden;
    }
  
    .futures-exchange-list-table tr {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      background-color: transparent;
      border: 0.5px solid var(--secondary-bg-color);
      border-radius: 10px;
      padding: 10px;
      box-shadow: var(--box-shadow);
      width: 100%;
      overflow: hidden;
    }
  
    .futures-exchange-list-table td {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      width: 100%;
      position: relative;
      overflow: hidden;
      white-space: normal;
      word-break: break-word;
    }
  
    .futures-exchange-list-table td:before {
      content: attr(data-label);
      font-weight: bold;
      margin-right: 10px;
      opacity: 0.9;
      color: var(--primary-color);
      flex: 0 0 auto;
      white-space: normal;
      word-break: break-word;
    }
  
    .futures-info-cell {
      order: -1;
      justify-content: flex-start;
      max-width: 100%;
      white-space: normal;
      word-break: break-word;
    }
  }
  
  /* Spinner für Ladevorgänge */
  .loading-spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  .spinner {
    margin-bottom: 10px;
    width: 40px;
    height: 40px;
    border: 4px solid #ccc;
    border-top-color: var(--primary-color);
    border-radius: 50%;
    animation: spin 0.6s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  /* Scrollleisten für Dark Mode */
  body.dark-mode::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  body.dark-mode::-webkit-scrollbar-track {
    background: var(--secondary-bg-color);
    border-radius: 4px;
  }
  
  body.dark-mode::-webkit-scrollbar-thumb {
    background: var(--primary-color);
    border-radius: 4px;
  }
  
  body.dark-mode::-webkit-scrollbar-thumb:hover {
    background: var(--btn-primary-bg-hover);
  }
  
  body.dark-mode {
    scrollbar-width: thin;
    scrollbar-color: var(--primary-color) var(--secondary-bg-color);
  }
  




  .sort-icon {
    margin-left: 5px;
    vertical-align: middle;
  }