/* common.css */

/* Grundlegende Styles für den Body */

/* Zurück-Button Stil */
.back-button {
  margin-top: 20px;
  background-color: var(--btn-primary-bg); /* Primärfarbe */
  color: var(--btn-success-text); /* Textfarbe */
  padding: var(--button-padding, 12px 25px);
  font-size: var(--button-font-size, 1rem);
  border: none;
  border-radius: var(--button-radius, 30px);
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.back-button:hover {
  background-color: var(--btn-primary-bg-hover); /* Hover-Farbe */
  transform: scale(1.05);
}
