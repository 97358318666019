/* src/styles/AdminNotifications.css */
.admin-notifications-container {
    width: 90%;
    max-width: 1200px;
    margin: 40px auto;
    padding: 20px;
    background-color: var(--background-color2);
    border-radius: 20px;
    box-shadow: var(--box-shadow);
    border: 1px solid var(--secondary-bg-color);
    color: var(--text-color);
  }
  
  .admin-notifications-container h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
    color: var(--primary-color);
  }
  
  /* Button "An Gruppen senden" */
  .btn-sendall {
    background-color: var(--background-color2);
    border: 1px solid var(--secondary-bg-color);
    border-radius: 6px;
    padding: 10px 16px;
    cursor: pointer;
    color: var(--text-color);
    margin-right: 10px; /* Abstand zum anderen Button */
    transition: background-color 0.2s ease;
  }
  .btn-sendall:hover {
    background-color: var(--background-color-hover);
  }
  
  /* Button "Alle löschen" (oben) */
  .btn-deleteall {
    background-color: var(--background-color2);
    border: 1px solid var(--secondary-bg-color);
    border-radius: 6px;
    padding: 10px 16px;
    cursor: pointer;
    color: var(--text-color);
    transition: background-color 0.2s ease;
  }
  .btn-deleteall:hover {
    background-color: var(--background-color-hover);
  }
  
  /* Tabelle */
  .admin-notifications-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .admin-notifications-table th,
  .admin-notifications-table td {
    padding: 12px;
    border-bottom: 1px solid var(--secondary-bg-color);
  }
  
  /* Aktion-Buttons in der Tabelle
     (z.B. "Löschen", "User-Notifs") 
  */
  .btn-notification-action {
    background-color: var(--background-color2);
    border: 1px solid var(--secondary-bg-color);
    border-radius: 6px;
    padding: 6px 12px;
    cursor: pointer;
    color: var(--text-color);
    margin-right: 5px; /* kleiner Abstand zwischen Buttons */
    transition: background-color 0.2s ease;
    font-size: 0.9rem; /* etwas kleiner, optional */
  }
  .btn-notification-action:hover {
    background-color: var(--background-color-hover);
  }
  
  /* Modal Overlay */
  .admin-notifications-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .admin-notifications-modal-content {
    background-color: var(--background-color2);
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 400px;
    color: var(--text-color);
    box-shadow: var(--box-shadow);
    text-align: center;
  }
  
  .form-group {
    margin: 15px 0;
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  
  /* Buttons im Modal */
  .modal-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  
  .modal-buttons button {
    background-color: var(--background-color2);
    border: 1px solid var(--secondary-bg-color);
    border-radius: 6px;
    padding: 10px 16px;
    cursor: pointer;
    color: var(--text-color);
    transition: background-color 0.2s ease;
  }
  .modal-buttons button:hover {
    background-color: var(--background-color-hover);
  }
  