/* CoinChart.css */

.coin-chart {
    text-align: center;
    padding: 20px;
  }
  
  .coin-chart h1 {
    font-size: 2.5rem;
    color: #0056b3; /* Dunkleres Blau für die Hauptüberschrift */
    margin-bottom: 20px;
  }
  
  .timeframe-selection {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .timeframe-selection label {
    font-size: 1.2rem;
    color: #343a40;
  }
  
  .timeframe-selection select {
    padding: 8px 12px;
    font-size: 1rem;
    border: 2px solid #007BFF;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    transition: border-color 0.3s;
  }
  
  .timeframe-selection select:hover {
    border-color: #0056b3;
  }
  
  .coin-chart .current-price {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 10px 0;
    color: #343a40;
  }
  
  .coin-chart .current-price .price {
    transition: color 0.3s ease;
  }
  
  .coin-chart .current-price .price.price-up {
    color: #28a745; /* Grün bei Anstieg */
  }
  
  .coin-chart .current-price .price.price-down {
    color: #dc3545; /* Rot bei Fall */
  }
  
  .coin-chart .chart-container {
    width: 100%;
    height: 400px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #ffffff; /* Weißer Hintergrund */
    border: 2px solid #007BFF; /* Primärfarbe für den Rand */
    border-radius: 10px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  .back-button {
    margin-top: 20px;
    background-color: #007BFF; /* Primärfarbe */
    color: #ffffff;
    padding: 12px 25px;
    font-size: 1rem;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: background 0.3s, transform 0.2s;
  }
  
  .back-button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }
  
  .loading,
  .error {
    font-size: 1.2rem;
    color: #343a40;
  }
  
  