/* TradingSimulator.css */

.trading-simulator {
  text-align: center;
  padding: 20px;
}

.trading-simulator h1 {
  font-size: 2.5rem;
  color: #0056b3; /* Dunkleres Blau für die Hauptüberschrift */
  margin-bottom: 20px;
}

.current-price {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 10px 0;
  color: #343a40;
}

.current-price .price {
  transition: color 0.3s ease, font-size 0.3s ease;
}

.current-price .price.up {
  color: #28a745; /* Grün bei Anstieg */
  font-size: 1.6rem;
}

.current-price .price.down {
  color: #dc3545; /* Rot bei Fall */
  font-size: 1.6rem;
}

.chart-container {
  width: 100%;
  height: 400px;
  max-width: 800px;
  margin: 0 auto;
}

.action-container {
  margin: 20px auto;
  padding: 15px;
  background-color: #ffffff; /* Weißer Hintergrund */
  border: 2px solid #007BFF; /* Primärfarbe für den Rand */
  border-radius: 10px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  max-width: 600px;
}

.balance {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #343a40;
}

.actions {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.actions button {
  padding: 12px 25px;
  font-size: 1rem;
  border: none;
  background-color: #007BFF; /* Primärfarbe */
  color: #ffffff;
  border-radius: 30px;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;
}

.actions button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.actions button:hover {
  background-color: #0056b3; /* Dunklere Variante beim Hover */
  transform: scale(1.05);
}

.message {
  margin: 20px auto;
  padding: 15px;
  border-radius: 10px;
  font-size: 1.2rem;
  font-weight: bold;
  max-width: 600px;
  text-align: center;
}

.message.info {
  background-color: #d9edf7;
  color: #31708f;
  border: 2px solid #bce8f1;
}

.message.success {
  background-color: #dff0d8;
  color: #3c763d;
  border: 2px solid #d6e9c6;
}

.message.error {
  background-color: #f2dede;
  color: #a94442;
  border: 2px solid #ebccd1;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.modal-content p {
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: #343a40;
}

.redirect-button,
.close-button {
  padding: 12px 25px;
  margin: 5px;
  font-size: 1rem;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;
}

.redirect-button {
  background-color: #007BFF; /* Primärfarbe */
  color: #ffffff;
}

.redirect-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.close-button {
  background-color: #dc3545; /* Rot für den Close-Button */
  color: #ffffff;
}

.close-button:hover {
  background-color: #c82333;
  transform: scale(1.05);
}

/* Back-Button Style */
.back-button {
  margin-top: 20px;
  background-color: #007BFF; /* Primärfarbe */
  color: #ffffff;
  padding: 12px 25px;
  font-size: 1rem;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;
}

.back-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}