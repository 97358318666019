/* src/styles/Breadcrumbs.css */

.breadcrumb {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    margin: 15px auto -20px; /* 15px oben, auto horizontal, -10px unten */
    max-width: 1200px;
    background-color: transparent;  /* z. B. weiße Farbe mit 80% Deckkraft */
    border: 1px solid var(--secondary-bg-color);
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-size: 1rem;
    color: var(--text-color);
  }
  
  .breadcrumb-item a {
    text-decoration: none;
    color: var(--primary-color);
    transition: color 0.3s ease;
  }
  
  .breadcrumb-item a:hover {
    color: var(--btn-primary-bg-hover);
  }
  
  .breadcrumb-item.active {
    color: var(--text-color);
  }
  
  .breadcrumb-item + .breadcrumb-item::before {
    content: "→";
    margin: 0 1px 0 0px; /* Falls hier weitere Anpassungen nötig sind, können auch hier negative Werte eingesetzt werden */
    color: var(--primary-color);
  }
  
  /* Responsive Anpassungen */
  @media (max-width: 768px) {
    .breadcrumb {
      width: 95%;
      padding: 4px 8px;
      margin: 15px auto -6px; /* Auch hier den unteren Margin anpassen */
      font-size: 0.8rem;
      border-radius: 10px;
    }
  }
  