/* src/styles/ContactForm.css */

.contact-form-container {
    width: 50%;
    max-width: 800px;
    background-color: transparent;
    border-radius: 20px;
    box-shadow: var(--box-shadow);
    margin: 40px auto;
    padding: 20px;
    color: var(--text-color);
    border: 0.5px solid var(--secondary-bg-color);
  }
  
  .contact-form-title {
    text-align: center;
    margin-bottom: 10px;
    font-size: 2rem;
    color: var(--primary-color);
  }
  
  /* Formular-Layout */
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .contact-form label {
    font-weight: bold;
  }
  
  .contact-form input[type="text"],
  .contact-form input[type="email"],
  .contact-form input[type="password"],
  .contact-form textarea {
    padding: 10px;
    border-radius: 10px;
    border: 1px solid var(--secondary-bg-color);
    background-color: var(--secondary-bg-color);
    color: var(--text-color);
  }
  
  .contact-form textarea {
    resize: vertical;
  }
  
  .contact-form-submit {
    width: 26%;
    background-color: var(--btn-primary-bg);
    color: #fff;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 1rem;
  }
  
  .contact-form-submit:hover {
    background-color: var(--btn-primary-bg-hover);
  }
  
  /* DSGVO-Checkbox */
  .privacy-checkbox {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .privacy-label {
    font-weight: normal; /* oder 400 */
    font-size: 0.8rem;   /* kannst du nach Geschmack anpassen */
  }
  
  .privacy-checkbox input[type="checkbox"] {
    width: 15px;
    height: 15px;
  }
  
  /* reCAPTCHA container */
  .g-recaptcha {
    margin: 15px 0;
  }
  
  /* Responsives Design */
  @media (max-width: 768px) {
    .contact-form-container {
      width: 95%;
    }

    .contact-form-submit {
        width: 72%;
      }
    
    .contact-form-title {
      font-size: 1.5rem;
    }
  }
  



  .contact-profile-badge {
    font-size: 0.8rem;
    color: #888;
    margin-left: 5px;
  }