  /* Modal-Overlay */
  .favorites-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Halbtransparenter Hintergrund */
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Modal-Content, mit Variablen wie in InfoTooltip.css */
  .favorites-modal-content {
    background: var(--background-color2);
    color: var(--text-color);
    border: 1px solid var(--border-color, #ccc);
    padding: 20px;
    width: 400px;
    max-width: 90%;
    border-radius: 8px;
    position: relative;
    box-shadow: 0 2px 8px rgba(0,0,0,0.2);
  
    /* Hier: Schriftgröße und Zeilenumbruch */
    font-size: 0.95rem;
    line-height: 1.4; /* Bisschen mehr Zeilenhöhe für Lesbarkeit */
    white-space: normal;
    word-wrap: break-word;      /* Alte Eigenschaft */
    overflow-wrap: break-word;  /* Moderne Eigenschaft */
  }
  
  
  /* Die Scrollbox für Ordner */
  .folder-list {
    max-height: 150px;
    overflow-y: auto;
    border: 1px solid var(--border-color, #ccc);
    padding: 8px;
    margin: 8px 0;
    border-radius: 4px;
    background: var(--background-color);
  }
  
  /* Einzelner Ordner-Eintrag */
  .folder-item {
    display: flex;          /* neu: Flex-Layout statt block */
    align-items: center;    /* vertikale Ausrichtung */
    padding: 4px 0;
    cursor: pointer;
  }
  
  .folder-item input[type="radio"] {
    margin-right: 8px;
  }
  
  /* Aktionen zum Ordnererstellen */
  .folder-create-actions {
    margin-top: 10px;
    display: flex;
    gap: 8px;
  }
  
  /* Footer-Actions (Speichern, Abbrechen) */
  .modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 16px;
  }
  
  .modal-actions button {
    background: var(--btn-primary-bg, #007bff);
    color: var(--btn-primary-color, #fff);
    border: none;
    border-radius: 4px;
    padding: 0.5em 1em;
    cursor: pointer;
  }
  
  .modal-actions button:hover {
    background: var(--btn-primary-bg-hover, #0056b3);
  }
  
  .folder-create-actions button {
    background: var(--btn-secondary-bg, #ccc);
    color: var(--btn-secondary-color, #000);
    border-radius: 4px;
    padding: 0.5em 1em;
    border: none;
    cursor: pointer;
  }
  
  .folder-create-actions button:hover {
    background: var(--btn-secondary-bg-hover, #bbb);
  }
  

  .favorites-modal-content h3 {
    font-weight: bold;      /* Titel fett */
    margin-bottom: 0.5rem;  /* optionaler Abstand */
  }
  
  /* Stelle sicher, dass normale Texte nicht fett sind */
  .favorites-modal-content p {
    font-weight: normal;    /* oder 400 */
    margin-bottom: 1rem;
  }
  
  .favorites-modal-content label {
    font-weight: normal;    /* Damit "Ordner-Name:" nicht fett ist */
  }
  
  /* Falls Input-Felder riesig aussehen, kann man sie hier anpassen */
  .favorites-modal-content input[type="text"] {
    font-size: 0.95rem;
    padding: 6px;
    width: 100%;
    margin-top: 4px;
  }




/* Basis-Stile für das Stern-Icon */
.favorite-star {
  cursor: pointer;
  margin-left: 8px; /* Standardwert für Desktop */
}

/* Standard-Stil für das Icon */
.favorite-star .favorite-star-icon {
  color: #999; /* Standardfarbe */
  transition: color 0.3s ease;
  width: 18px;
  height: 18px;
}

/* Standard-Stil für das Icon */
.favorite-star .favorite-star-icon-full {
  color: #f1c40f; /* Standardfarbe */
  transition: color 0.3s ease;
  width: 18px;
  height: 18px;
}


/* Hover-Effekt: Farbe ändern */
.favorite-star:hover .favorite-star-icon {
  color: #f1c40f; /* Standardfarbe */
}

.favorite-star:hover .favorite-star-icon-full {
  color: #999; /* Standardfarbe */
}

/* Mobile-spezifische Anpassungen */
@media screen and (max-width: 767px) {
  .favorite-star {
    margin-left: 5px;
  }
  
  .favorite-star .favorite-star-icon .favorite-star-icon-full {
    width: 20px;
    height: 20px;
  }
}


/* Für alle Modal-Container den Standard-Cursor erzwingen */
.favorites-modal-backdrop,
.favorites-modal-content {
  cursor: default;
}

/* Klickbare Elemente sollen weiterhin den Pointer-Cursor haben */
.modal-actions button,
.folder-create-actions button,
.favorites-modal-footer a,
.folder-item input[type="radio"] {
  cursor: pointer;
}


.favorites-link {
  color: var(--link-color);
  cursor: pointer;
  font-size: 0.8rem;
  text-decoration: none;
}

.favorites-link:hover {
  color: var(--link-hover-color);
  text-decoration: underline;
}


.favorites-modal-footer {
  text-align: right;   /* Linksbündig */
  margin-top: -14px;    
  margin-bottom: 5px;
  margin-right: -4px;;
}