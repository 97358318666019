/* Coins Section Container */
.coins-section {
  width: 90%;
  max-width: 800px;
  margin: 40px auto;
  padding: 30px 20px;
  background-color: var(--background-color2);
  text-align: center;
  border-radius: 20px;
  box-shadow: var(--box-shadow);
  color: var(--text-color);
  border: 1px solid var(--secondary-bg-color); /* Hinzugefügter Rahmen */
}

/* Hauptüberschrift der Sektion */
.coins-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: var(--primary-color);
}

/* Einleitender Text */
.coins-section p {
  font-size: 1.1rem;
  color: var(--text-color);
  margin-bottom: 40px;
  line-height: 1.6;
}

/* Coin-Karten (Top-Coins, Alt-Coins, Meme-Coins) */
.coin-card {
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  background: var(--background-color2);
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  height: 100%;
  width: 100%;
  border: 1px solid var(--secondary-bg-color); /* Hinzugefügter Rahmen für die Karten */
}

/* Hover-Effekt bei Coin-Karten */
.coin-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--box-shadow-inset);
}

/* Icon-Größe in den Karten */
.coin-card svg {
  width: 50px;
  height: 50px;
  color: var(--primary-color);
}

/* Titel in der Coin-Karte */
.coin-card .card-title {
  font-size: 1.5rem;
  margin-top: 15px;
  color: var(--primary-color);
}

/* Beschreibungstext in der Coin-Karte */
.coin-card .card-text {
  color: var(--text-color);
}

/* Abschnitt für das Balkendiagramm (Marktanteilsverhältnis) */
.coin-ratio-chart {
  margin-top: 40px;
  height: 300px;
}

.coin-ratio-chart h3 {
  font-size: 1.5rem;
  color: var(--primary-color);
  margin-bottom: 20px;
}

/* Erklärung der Wertigkeit von Coins */
.coin-value-explanation {
  margin-top: 80px;
}

.coin-value-explanation h3 {
  font-size: 1.5rem;
  color: var(--primary-color);
  margin-bottom: 20px;
}

.coin-value-explanation p {
  font-size: 1rem;
  color: var(--text-color);
  line-height: 1.6;
}

/* Modal Anpassungen */
/* Modal Gesamtcontainer anpassen */
.modal-content {
  background-color: var(--background-color2);
  color: var(--text-color);
  border-radius: 15px;
  box-shadow: var(--box-shadow);
  border: 1px solid var(--secondary-bg-color); /* falls ein Rahmen gewünscht ist */
}

.modal-title {
  color: var(--primary-color);
  font-weight: bold;
  text-align: center;
}

.modal-body p {
  line-height: 1.5;
  text-align: justify;
}

.modal-body ul {
  list-style: none;
  padding: 0;
}

.modal-body ul li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 5px;
  background: var(--secondary-bg-color);
  border-radius: 8px;
  box-shadow: var(--box-shadow-inset);
}

.modal-body ul li strong {
  margin-left: 10px;
  color: var(--text-color);
}

.modal-body ul li svg {
  flex-shrink: 0;
  color: var(--primary-color);
  width: 25px;
  height: 25px;
}

.modal-footer button {
  border-radius: var(--button-radius);
  padding: var(--button-padding);
  background-color: var(--btn-primary-bg);
  color: var(--btn-success-text);
  border: none;
  transition: background-color 0.3s ease, transform 0.2s;
}

.modal-footer button:hover {
  background-color: var(--btn-primary-bg-hover);
  transform: scale(1.05);
}

/* Responsive Anpassungen */
@media (max-width: 768px) {
  .coin-ratio-chart {
    margin-top: 30px;
    height: 250px;
  }

  .coin-value-explanation {
    margin-top: 40px;
  }

  .coins-section h2 {
    font-size: 1.8rem;
  }

  .coins-section p {
    font-size: 1rem;
  }

  .coin-card svg {
    width: 40px;
    height: 40px;
  }

  .coin-ratio-chart h3 {
    font-size: 1.3rem;
  }

  .coin-value-explanation h3 {
    font-size: 1.3rem;
  }

  .modal-body {
    padding: 15px;
  }
}



body.dark-mode .modal-backdrop.show {
  background-color: var(--background-color2) !important;
}

body.dark-mode .modal-content {
  background-color: var(--background-color2) !important;
  color: var(--text-color) !important;
  border-radius: 15px;
  border: 1px solid var(--secondary-bg-color) !important;
  box-shadow: var(--box-shadow);
}

body.dark-mode .modal-header,
body.dark-mode .modal-footer {
  background-color: var(--background-color2) !important;
  border: none !important;
}

body.dark-mode .modal-body {
  background-color: var(--background-color2) !important;
  color: var(--text-color) !important;
}

body.dark-mode .modal-body,
body.dark-mode .modal-body p,
body.dark-mode .modal-body ul li,
body.dark-mode .modal-content {
  color: var(--text-color) !important;
}