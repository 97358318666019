/* src/styles/AdminStats.css */

.admin-stats-container {
    width: 90%;
    max-width: 1200px;
    margin: 40px auto;
    padding: 20px;
    background-color: var(--background-color2);
    border-radius: 20px;
    box-shadow: var(--box-shadow);
    border: 1px solid var(--secondary-bg-color);
    color: var(--text-color);
  }
  
  .admin-stats-container h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
    color: var(--primary-color);
  }
  
  /* 
    Grid für die einzelnen Stats-Sektionen.
    - Mobile => 1 Spalte
    - Desktop (>=992px) => 2 Spalten
  */
  .stats-sections-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin-top: 30px;
  }
  
  @media (min-width: 992px) {
    .stats-sections-grid {
      grid-template-columns: 1fr 1fr; /* zwei Spalten ab großer Breite */
    }
  }
  
  /* 
    Jeder Abschnitt in der Grid 
  */
  .stats-section {
    background-color: var(--background-color3);
    border-radius: 10px;
    box-shadow: var(--box-shadow);
    padding: 16px;
    position: relative;
  }
  
  /* Titel im Abschnitt */
  .stats-section h3 {
    font-size: 1.2rem;
    margin-bottom: 12px;
    color: var(--primary-color);
  }
  
  /* Chart-Wrapper:
     - Etwas kompaktere Höhe
     - Polsterung
  */
  .chart-wrapper {
    width: 100%;
    height: 320px; /* du kannst das anpassen */
    margin: 0 auto;
    position: relative;
    border-radius: 6px;
    background-color: var(--background-color2);
    padding: 8px;
  }
  
  /* Responsive-Anpassung für wirklich kleine Screens */
  @media (max-width: 576px) {
    .chart-wrapper {
      height: 250px;
    }
  }
  