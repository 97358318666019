/* Globale Einstellungen */
* {
  box-sizing: border-box;
}

.newly-listed-container {
  width: 90%;
  max-width: 1200px;
  background-color: transparent;
  border-radius: 20px;
  box-shadow: var(--box-shadow);
  margin: 40px auto;
  padding: 20px;
  color: var(--text-color);
  border: 0px solid var(--secondary-bg-color);
}

.title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
  color: var(--primary-color);
}

/* Neue Klassen für die obere Control-Leiste */
.newly-listed-controls {
  display: flex;                
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* Coins pro Seite */
.newly-listed-coins-per-page {
  display: flex;
  flex-direction: column;  /* Vertikale Anordnung: Label über Select */
  align-items: flex-start; /* Linksbündig */
  gap: 4px;                /* Abstand zwischen Label und Select – passe den Wert nach Bedarf an */
}

.newly-listed-coins-per-page label {
  margin: 0;
}

/* Selectors */
.newly-listed-coins-per-page select,
.newly-listed-currency-selector select {
  padding: 5px 10px;
  border-radius: 10px;
  border: 1px solid var(--secondary-bg-color);
  background-color: var(--secondary-bg-color);
  color: var(--text-color);
}

/* Währungs-Selektor */
.newly-listed-currency-selector {
  display: flex;
  align-items: center;
  gap: 10px; /* Abstände zwischen Label und React-Select */
}

.newly-listed-currency-selector label {
  margin-right: 10px;
}

/* Pagination Controls */
.pagination-controls button {
  background-color: var(--btn-primary-bg);
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination-controls button:hover:not(:disabled) {
  background-color: var(--btn-primary-bg-hover);
}

.pagination-controls button:disabled {
  background-color: var(--btn-secondary-bg);
  cursor: not-allowed;
}

.pagination-controls span {
  margin: 0 10px;
  color: var(--text-color);
}

.newly-listed-table {
  width: 100%;
  border-collapse: collapse;
}

.newly-listed-table th,
.newly-listed-table td {
  text-align: left;
  padding: 10px;
  border-bottom: 1px solid var(--secondary-bg-color);
  vertical-align: middle;
  line-height: 1.2;
  max-width: 100%;
  overflow: hidden;
}

.newly-listed-table tbody tr:hover {
  background-color: var(--secondary-bg-color);
  cursor: pointer;
}

.coin-info-cell {
  gap: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.coin-info-cell img,
.coin-info-cell .coin-name,
.coin-info-cell .coin-symbol {
  vertical-align: middle;
}

.coin-logo,
.coin-name,
.coin-symbol {
  display: inline-block;
  vertical-align: middle;
  line-height: 1.2;
}

.coin-logo {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
}

.coin-name {
  font-weight: bold;
  color: var(--text-color);
  margin-right: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.coin-symbol {
  opacity: 0.7;
}

.price-up {
  color: var(--price-up-color);
}

.price-down {
  color: var(--price-down-color);
}

.loading,
.error-message {
  text-align: center;
  margin: 20px 0;
  font-size: 1.1rem;
  font-weight: bold;
  color: var(--text-color);
}

/* Highlight-Effekte */
.highlight-up {
  animation: highlightValueGreen 0.5s ease-in-out;
  border-radius: 3px;
  padding: 1px 2px;
}

.highlight-down {
  animation: highlightValueRed 0.5s ease-in-out;
  border-radius: 3px;
  padding: 1px 2px;
}

@keyframes highlightValueGreen {
  0% {
    background-color: #e6f7e6;
  }
  50% {
    background-color: #dff0df;
  }
  100% {
    background-color: transparent;
  }
}

@keyframes highlightValueRed {
  0% {
    background-color: #fce6e6;
  }
  50% {
    background-color: #f7dcdc;
  }
  100% {
    background-color: transparent;
  }
}

.desktop-only {
  display: inline-block;
}

.mobile-only {
  display: none;
  margin-top: 5px;
}

/* --- Responsive Anpassungen (Handy / Tablet) --- */
@media (max-width: 768px) {
  .newly-listed-container {
    width: 95%;
    max-width: 1200px;
    background-color: transparent;
    border-radius: 20px;
    box-shadow: var(--box-shadow);
    margin: 40px auto;
    padding: 10px;
    color: var(--text-color);
    border: 0.5px solid var(--secondary-bg-color);
    overflow-x: hidden; 
  }
  
  .title {
    font-size: 1.5rem;
    margin-top: 5px;
  }

  /* Steckt "Coins pro Seite" und "Währung" untereinander */
  .newly-listed-controls {
    flex-direction: column;
    align-items: flex-start; /* Links ausgerichtet oder center, wie du magst */
    gap: 10px;              /* Abstand zwischen den beiden Gruppen */
  }

  /* Jede Gruppe füllt die ganze Breite */
  .newly-listed-coins-per-page,
  .newly-listed-currency-selector {
    width: 100%;
    justify-content: flex-start;
  }

  .newly-listed-table {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .newly-listed-table thead {
    display: none;
  }

  .newly-listed-table tbody {
    display: block;
    width: 100%;
    overflow-x: hidden;
  }

  .newly-listed-table tr {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    background-color: transparent;
    border: 0.5px solid var(--secondary-bg-color);
    border-radius: 10px;
    padding: 10px;
    box-shadow: var(--box-shadow);
    width: 100%;
    overflow: hidden;
  }

  .newly-listed-table td {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    width: 100%;
    position: relative;
    overflow: hidden;
    white-space: normal;
    word-break: break-word;
  }

  .newly-listed-table td:before {
    content: attr(data-label);
    font-weight: bold;
    margin-right: 10px;
    opacity: 0.9;
    color: var(--primary-color);
    flex: 0 0 auto;
    white-space: normal;
    word-break: break-word;
  }

  .newly-listed-table td.coin-info-cell {
    order: -1;
    justify-content: flex-start;
    max-width: 100%;
    white-space: normal;
    word-break: break-word;
  }

  .coin-name {
    white-space: normal;
    word-break: break-word;
  }

  .desktop-only {
    display: none;
  }

  .coin-symbol {
    display: none;
  }

  .mobile-only {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    white-space: normal;
    word-break: break-word;
  }

  .mobile-only:before {
    content: attr(data-label);
    font-weight: bold;
    margin-right: 10px;
    color: var(--primary-color);
  }
}
