/* src/styles/GLOBALSTYLE.css */

/* Grundlegende Body-Stile */
body {
    margin: 0;
    padding: 0;
    font-family: var(--font-family);
    background-color: var(--background-color);
    color: var(--text-color);
}

:root {
    --background-color: #f1f1f1;
    --background-color2: #ffffff;
    --background-gradient: linear-gradient(135deg, #f0f4f8 25%, #d9e2ec 100%);

    --text-color: #343a40;
    --text-color-input: #343a40;

    --primary-color: #007BFF;
    --primary-hover-color: #0056b3;
    --secondary-bg-color: #f0f0f0;

    --price-up-color: green;
    --price-down-color: red;
    --price-neutral-color: rgb(202, 202, 202);
    --price-up-bg: #e6ffe6;
    --price-up-mid: #ccffcc;
    --price-down-bg: #ffe6e6;
    --price-down-mid: #ffcccc;

    --logout-color: red;
    --logout-hover-color: darkred;

    --box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    --box-shadow-inset: inset 0 2px 5px rgba(0, 0, 0, 0.1);


    --button-font-size: 1rem;
    --button-radius: 30px;
    --button-padding: 10px 20px;

    --btn-primary-bg: var(--primary-color);
    --btn-primary-bg-hover: var(--primary-hover-color);
    --btn-secondary-bg: #6c757d;
    --btn-secondary-bg-hover: #5a6268;

    --btn-success-bg: #28a745;
    --btn-success-bg-hover: #218838;
    --btn-success-text: #ffffff;

    --font-family: Arial, sans-serif;

    /* Neue Toast-Variablen für Light Mode */
    --toast-bg: #ffffff;
    --toast-text: #343a40;
    --toast-success-bg: #e1fae1;
    /* zartes Grün für Erfolg */
    --toast-error-bg: #fae1e1;
    /* zartes Rot für Fehler */
    --toast-info-bg: #e1e7fa;
    /* zartes Blau für Infos */
    --toast-progress-bg: var(--primary-color);
    /* nutzt deine Primärfarbe */
}
