/* src/styles/ResetPassword.css */

.reset-password-container {
  width: 90%;
  max-width: 500px;                   
  background-color: transparent;
  border-radius: 20px;
  box-shadow: var(--box-shadow);
  margin: 40px auto;
  padding: 30px;
  text-align: center;                   /* ggf. anders als Profile, hier bleibst du bei center */
  color: var(--text-color);            /* Wichtig für Darkmode */
  border: 0.5px solid var(--secondary-bg-color); /* Rahmen wie in anderen Containern */
}

.reset-password-container h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: var(--primary-color);
}

/* Das Formular */
.reset-password-container form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Inputs */
.reset-password-container input {
  padding: 10px;
  font-size: 1.1rem;
  border: 1px solid var(--secondary-bg-color);
  border-radius: 10px;
  background-color: var(--background-color);
  color: var(--text-color);
  /* Optional: Schatten oder so:
     box-shadow: inset 0 2px 5px rgba(0,0,0,0.1);
  */
  outline: none; /* Kein spezieller Outline */
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Button */
.reset-password-container button {
  padding: 12px;
  font-size: 1.1rem;
  background-color: var(--btn-primary-bg);
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.reset-password-container button:hover:not(:disabled) {
  background-color: var(--btn-primary-bg-hover);
  transform: scale(1.05);
}

.reset-password-container button:disabled {
  background-color: var(--btn-secondary-bg);
  cursor: not-allowed;
  transform: none;
}

/* Links-Bereich */
.reset-password-container .links {
  margin-top: 20px;
  text-align: center;
}

.reset-password-container .links a {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.reset-password-container .links a:hover {
  color: var(--primary-hover-color);
}

/* Responsive Anpassung */
@media (max-width: 768px) {
  .reset-password-container {
    width: 95%;
    padding: 20px;
  }

  .reset-password-container button {
    font-size: 1rem;
    width: 100%;
  }
}
