/* src/styles/Footer.css */

/* Die Standard-Regeln (Desktop/weit) */
.footer_main {
  width: 100%;
  background-color: transparent;
  border-top: 0.2px solid var(--secondary-bg-color);
  /* Schrift insgesamt etwas kleiner */
  font-size: 0.9rem; /* z.B. 0.9rem statt 1rem */
}

.footer_content {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 60px;
  padding: 0 30px;
  justify-content: space-between;
  margin-top: 8px;
}

.footer_left-group {
  display: flex;
  gap: 15px;
  justify-content: flex-start;
  flex: 0 0 auto;
}

.footer_copyright {
  text-align: center;
  flex: 1 1 auto;
}

.footer_right-group {
  display: flex;
  gap: 15px;
  justify-content: flex-end;
  flex: 0 0 auto;
}

/* Social Icons */
.footer-social-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  /* Icon-Größe hier verkleinern: */
  font-size: 1rem; /* vorher 1.2rem */
  width: 32px;     /* vorher 40px */
  height: 32px;    /* vorher 40px */
  border-radius: 50%;
  color: var(--text-color);
  border: 0.5px solid var(--secondary-bg-color);
  background-color: transparent;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.footer-social-link:hover {
  background-color: var(--btn-primary-bg-hover);
  color: #fff;
}

/* Link-Styling (Impressum, Datenschutz, Kontakt) */
.footer-link {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  text-decoration: none;
  color: var(--text-color);
  background-color: transparent;
  border: 0.5px solid var(--secondary-bg-color);
  border-radius: 20px;
  /* Schrift und Padding etwas verkleinern: */
  font-size: 0.85rem; /* vorher 0.95rem */
  padding: 0.3rem 0.6rem; /* statt 0.4rem 0.8rem */
  transition: background-color 0.3s ease, color 0.3s ease;
}
.footer-link:hover {
  background-color: var(--btn-primary-bg-hover);
  color: #fff;
}
.footer-link-icon {
  font-size: 1rem; /* etwas kleiner als vorher 1.1rem */
}

/* =========== Media Query ab <=1200px =========== */
/* Wenn du ab 1200px das "mobile" Layout zeigen willst: */
@media (max-width: 1100px) {
  .footer_content {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 10px;
  }

  .footer_left-group,
  .footer_copyright,
  .footer_right-group {
    flex: 0 0 auto;
    margin-bottom: 8px;
    justify-content: center;
  }
}




.footer-social-link.disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5; /* optional: visuelle Kennzeichnung */
}