.trades-page {
  padding: 20px;
  font-family: var(--font-family);
  background-color: var(--background-color);
  color: var(--text-color);
  text-align: center;
}

/* Überschrift mit Primärfarbe */
.trades-page h1 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: var(--primary-color);
}

/* Aktueller Preis Styling */
.current-price {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
  display: inline-block;
}

.current-price.price-up {
  color: var(--price-up-color);
  background-color: var(--price-up-bg);
  animation: highlight 0.5s ease-in-out;
}

.current-price.price-down {
  color: var(--price-down-color);
  background-color: var(--price-down-bg);
  animation: highlight 0.5s ease-in-out;
}

@keyframes highlight {
  from {
    transform: scale(1.1);
  }
  to {
    transform: scale(1);
  }
}

/* Tabellencontainer mit globalen Variablen */
.trades-table-wrapper {
  overflow-x: auto;
  margin: 0 auto;
  max-width: 90%;
  background: var(--background-color2);
  border-radius: 8px;
  box-shadow: var(--box-shadow);
}

/* Tabellenkopf mit Primärfarbe und globaler Textfarbe */
.trades-table th {
  background-color: var(--secondary-bg-color);
  color: var(--btn-success-text, #ffffff);
  padding: 10px 15px;
  text-transform: uppercase;
  font-size: 0.9rem;
}

/* Spezifische Styles für Light Mode (kein .dark-mode am Body) */
body:not(.dark-mode) .trades-table th {
  background-color: var(--primary-color);
  color: var(--btn-success-text, #ffffff);
}

/* Spezifische Styles für Dark Mode (.dark-mode am Body) */
body.dark-mode .trades-table th {
  background-color: var(--secondary-bg-color);
  color: var(--btn-success-text, #ffffff);
}

.trades-table {
  width: 100%;
  border-collapse: collapse;
}

.trades-table td {
  padding: 10px 15px;
  text-align: center;
  font-size: 0.95rem;
  color: var(--text-color);
}

/* Wechselnde Zeilenfarben */
.trades-table tr:nth-child(even) {
  background-color: var(--secondary-bg-color);
}

/* Hover-Effekt mit globaler Hover-Farbe */
.trades-table tr:hover {
  background-color: var(--btn-secondary-bg-hover);
  transition: background-color 0.3s ease;
}

/* Farbliche Kennzeichnung von Kauf/Verkauf */
.buy-row td {
  color: var(--price-up-color);
}

.sell-row td {
  color: var(--price-down-color);
}
