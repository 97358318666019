/* src/styles/LandingPage.css */

/* Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');

.landing-page {
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--background-color);  
  min-height: 100vh;
  padding-bottom: 50px;
}

/* BTC-Preis Styling */
.crypto-price {
  margin-top: 15px;
  font-size: 1.2rem;
  color: var(--text-color);
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 10px;
}

.crypto-price span:first-child {
  font-size: 1.5rem;
}

.price-up {
  color: var(--price-up-color, #28a745); /* Grün */
}

.price-down {
  color: var(--price-down-color, #dc3545); /* Rot */
}

/* Popover Anpassungen */
.popover {
  max-width: 300px;
}

.popover-header {
  background-color: var(--primary-color);
  color: var(--btn-info-text, white);
}

.popover-body {
  color: var(--text-color);
}

/* Grundlagen des Kryptokaufs und Tradings */
/* Anpassung: Gleicher Look wie bei BuyingSimulator & CoinSection */
.fundamentals-section {
  width: 90%;
  max-width: 800px;
  background-color: var(--background-color2);
  border-radius: 20px;
  box-shadow: var(--box-shadow);
  margin: 40px auto;
  padding: 30px;
  text-align: left;
  border: 1px solid var(--secondary-bg-color); /* Einheitlicher Rand wie bei den anderen Containern */
}

.fundamentals-section h2 {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 20px;
}

.fundamentals-section p {
  font-size: 1.1rem;
  color: var(--text-color);
  line-height: 1.6;
}

/* Gesamtanzahl der Münzen */
.total-coins {
  margin-top: 20px;
  font-size: 1.2rem;
  color: var(--text-color);
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

/* Sharing-Buttons Styling */
.share-buttons {
  margin-top: 100px;
  text-align: center;
}

.share-buttons p {
  font-size: 1rem;
  margin-bottom: 10px;
  color: var(--text-color);
}

.share-buttons > * {
  margin: 0 5px;
}

/* Responsive Anpassungen */
@media (max-width: 768px) {
  .fundamentals-section {
    padding: 20px;
  }

  .fundamental-item {
    margin-top: 15px;
  }

  .cards-container {
    margin-top: 30px;
  }

  .chart-container {
    height: 200px;
  }
}
