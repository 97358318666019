.casino-slot-machine {
  text-align: center;
  padding: 20px;
  background-color: var(--background-color);
  color: var(--text-color);
}

.casino-slot-machine h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: var(--primary-color);
}

.casino-slot-machine .balance {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: var(--text-color);
}

.slot-machine {
  margin: 0 auto;
  padding: 20px;
  background-color: var(--background-color2);
  border: 5px solid var(--primary-color);
  border-radius: 20px;
  width: 400px;
  box-shadow: var(--box-shadow);
}

.reels {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reel-row {
  display: flex;
  justify-content: center;
}

.reel {
  position: relative;
  font-size: 3rem;
  background-color: var(--secondary-bg-color);
  width: 90px;
  height: 90px;
  line-height: 90px;
  margin: 5px;
  border-radius: 10px;
  border: 2px solid var(--primary-color);
  box-shadow: var(--box-shadow-inset);
  transition: transform 0.5s ease;
  color: var(--text-color);
  text-align: center;
}

.spinning {
  animation: spin 1s infinite;
}

@keyframes spin {
  0% { transform: rotateX(0deg); }
  100% { transform: rotateX(360deg); }
}

.controls {
  margin-top: 20px;
}

.controls input[type="number"] {
  width: 80px;
  padding: 10px;
  font-size: 1rem;
  margin-right: 10px;
  border: 2px solid var(--primary-color);
  border-radius: 5px;
  color: var(--text-color);
  background-color: var(--background-color2);
}

.controls button {
  padding: 12px 25px;
  font-size: 1rem;
  border: none;
  background-color: var(--btn-primary-bg);
  color: var(--btn-success-text, #ffffff);
  border-radius: var(--button-radius, 30px);
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;
}

.controls button:hover {
  background-color: var(--btn-primary-bg-hover);
  transform: scale(1.05);
}

.message {
  font-size: 1.5rem;
  margin: 30px 0;
  color: var(--text-color);
  text-align: center;
}

/* Wir nutzen für das "Du hast keine Punkte mehr"-Fenster eine "Error-/Warnfarbe" */
.redirect-message {
  margin: 20px auto;
  padding: 20px;
  background-color: var(--toast-error-bg);
  border: 2px solid var(--price-down-mid);
  border-radius: 10px;
  max-width: 600px;
}

.redirect-message p {
  font-size: 1.2rem;
  margin-bottom: 15px;
  color: var(--text-color);
  text-align: center;
}

.redirect-message button {
  padding: 12px 25px;
  font-size: 1rem;
  border: none;
  background-color: var(--logout-color);
  color: var(--btn-success-text, #ffffff);
  border-radius: var(--button-radius, 30px);
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;
}

.redirect-message button:hover {
  background-color: var(--logout-hover-color);
  transform: scale(1.05);
}

.back-button {
  margin-top: 20px;
  background-color: var(--btn-primary-bg);
  color: var(--btn-success-text, #ffffff);
  padding: 12px 25px;
  font-size: 1rem;
  border: none;
  border-radius: var(--button-radius, 30px);
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;
}

.back-button:hover {
  background-color: var(--btn-primary-bg-hover);
  transform: scale(1.05);
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: var(--background-color2);
  padding: 30px;
  border-radius: 20px;
  text-align: center;
  width: 80%;
  max-width: 400px;
  box-shadow: var(--box-shadow);
}

.modal-content p {
  font-size: 1.4rem;
  margin-bottom: 25px;
  color: var(--text-color);
}

.redirect-button,
.close-button {
  padding: 12px 25px;
  margin: 10px;
  font-size: 1rem;
  border: none;
  border-radius: var(--button-radius, 30px);
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;
}

/* Redirect-Button im Modal nutzt Primärfarben */
.redirect-button {
  background-color: var(--btn-primary-bg);
  color: var(--btn-success-text, #ffffff);
}

.redirect-button:hover {
  background-color: var(--btn-primary-bg-hover);
  transform: scale(1.05);
}

/* Close-Button nutzt Sekundärfarben */
.close-button {
  background-color: var(--btn-secondary-bg);
  color: var(--btn-success-text, #ffffff);
}

.close-button:hover {
  background-color: var(--btn-secondary-bg-hover);
  transform: scale(1.05);
}

/* Unterschiedliche Styles im Darkmode: falls nötig, kann man hier noch 
   spezifische Dark-Mode-Anpassungen machen, z.B.:
   
body.dark-mode .casino-slot-machine h1 {
  color: var(--primary-color);
}
   
Aber dank der Variablen sollte alles dynamisch funktionieren.
*/
