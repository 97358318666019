/* src/styles/WhatIsCrypto.css */

/* Container für den gesamten Bereich "Was ist Krypto?" */
.what-is-crypto {
  width: 90%;
  max-width: 800px;
  background-color: var(--background-color);
  border-radius: 20px;
  box-shadow: var(--box-shadow);
  margin: 20px auto;       /* Außenabstand */
  padding: 20px;           /* Innenabstand */
  color: var(--text-color); /* Globale Textfarbe */
}

/* Bild oben, linksbündig */
.crypto-graphic-header {
  width: 90%;            /* Größe des Bildes */
  height: auto;            /* Automatische Höhe */
  display: block;
}

/* Übersichtskarte Inhalt */
.overview-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Text unterhalb des Logos bleibt linksbündig */
  gap: 20px;
}

/* Textinhalt in der Übersicht */
.overview-text {
  text-align: left;        /* Text linksbündig */
  width: 100%;
}

.overview-text p {
  font-size: 1.1rem;
  color: var(--text-color);
  line-height: 1.6;
}

/* Kategorien */
.categories {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

.category-item {
  background-color: var(--secondary-bg-color); /* Sekundäre Hintergrundfarbe */
  padding: 10px 15px;
  border-radius: var(--button-radius, 10px);
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  font-size: 1.2rem;
  color: var(--text-color); /* Konsistente Textfarbe */
}

.category-item:hover {
  background-color: var(--btn-secondary-bg-hover, #e0e0e0); /* Hover-Farbe */
  transform: scale(1.05);
}

/* Responsive Design */
@media (max-width: 768px) {
  .crypto-graphic-header {
    width: 90%;          /* Logo wird kleiner auf kleinen Bildschirmen */
  }

  .overview-text {
    text-align: center;
  }

  .categories {
    flex-direction: column;
    align-items: center;
  }
}

/* Popover Anpassungen */
.popover {
  max-width: 300px;
  background-color: var(--background-color); /* Hintergrundfarbe angepasst */
  color: var(--text-color);                 /* Textfarbe angepasst */
  border: 1px solid var(--secondary-bg-color); /* Rahmenfarbe */
  box-shadow: var(--box-shadow);           /* Konsistenter Schatten */
}

.popover-header {
  background-color: var(--primary-color);   /* Header mit Primärfarbe */
  color: var(--btn-success-text, white);    /* Textfarbe des Headers */
  font-weight: bold;
  padding: 10px 15px;
  border-bottom: 1px solid var(--secondary-bg-color); /* Abtrennung zum Body */
  border-radius: 10px 10px 0 0; /* Abgerundete Ecken oben */
}

.popover-body {
  color: var(--text-color); /* Konsistente Textfarbe */
  padding: 15px;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0 0 10px 10px; /* Abgerundete Ecken unten */
}

/* Popover Pfeil */
.popover-arrow {
  border-color: var(--background-color); /* Pfeil an Hintergrundfarbe angepasst */
}

/* Hover-Effekt auf Kategorien für bessere UX */
.category-item:hover {
  background-color: var(--btn-primary-bg-hover); /* Konsistente Hover-Farbe */
  color: var(--btn-success-text, white);         /* Textfarbe beim Hover */
}
