/* src/styles/FuturesExchangeDetails.css */
* {
  box-sizing: border-box;
}

.futures-exchange-details-container {
  width: 90%;
  max-width: 1200px;
  background-color: transparent;
  border-radius: 20px;
  box-shadow: var(--box-shadow);
  margin: 40px auto;
  padding: 20px;
  color: var(--text-color);
  border: 0px solid var(--secondary-bg-color);
}

.futures-exchange-details-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
  color: var(--primary-color);
}

.back-button {
  display: block;
  margin: 0 auto 20px auto;
  padding: 5px 10px;
  border-radius: 10px;
  border: none;
  background-color: var(--btn-primary-bg);
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.back-button:hover:not(:disabled) {
  background-color: var(--btn-primary-bg-hover);
}

/* Header-Bereich für die aktuelle Exchange */
.exchange-header {
  text-align: center;
  margin-bottom: 20px;
  padding: 10px;
  background-color: var(--secondary-bg-color);
  border-radius: 10px;
}
.exchange-header h3 {
  margin: 0;
  font-size: 1.8rem;
  color: var(--primary-color);
}

/* Summary-Bereich für aggregierte Werte */
.exchange-summary {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  background-color: var(--secondary-bg-color);
  border-radius: 10px;
}

.summary-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.summary-label {
  font-weight: bold;
  margin-bottom: 5px;
}

.summary-value {
  font-size: 1.2rem;
}

/* Sortier- und Eintrags-Auswahl-Kontrollen */
.details-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
  gap: 10px;
}

.details-sort-controls,
.details-per-page {
  display: flex;
  align-items: center;
  gap: 10px;
}

.sort-icon {
  margin-left: 5px;
  vertical-align: middle;
}

/* Tabelle */
.futures-exchange-details-table {
  width: 100%;
  border-collapse: collapse;
}

.futures-exchange-details-table th,
.futures-exchange-details-table td {
  text-align: left;
  padding: 10px;
  border-bottom: 1px solid var(--secondary-bg-color);
  vertical-align: middle;
  line-height: 1.2;
}

.futures-exchange-details-table th {
  font-weight: bold;
  cursor: default;
}

.futures-exchange-details-table tbody tr:hover {
  background-color: var(--secondary-bg-color);
  cursor: pointer;
}

/* Responsive Anpassungen */
@media (max-width: 768px) {
  .futures-exchange-details-container {
    width: 95%;
    max-width: 1200px;
    padding: 10px;
    border: 0.5px solid var(--secondary-bg-color);
    overflow-x: hidden;
  }
  
  .futures-exchange-details-table thead {
    display: none;
  }
  
  .futures-exchange-details-table tbody {
    display: block;
    width: 100%;
    overflow-x: hidden;
  }
  
  .futures-exchange-details-table tr {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    background-color: transparent;
    border: 0.5px solid var(--secondary-bg-color);
    border-radius: 10px;
    padding: 10px;
    box-shadow: var(--box-shadow);
    width: 100%;
    overflow: hidden;
  }
  
  .futures-exchange-details-table td {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    width: 100%;
    position: relative;
    overflow: hidden;
    white-space: normal;
    word-break: break-word;
  }
  
  .futures-exchange-details-table td:before {
    content: attr(data-label);
    font-weight: bold;
    margin-right: 10px;
    opacity: 0.9;
    color: var(--primary-color);
    flex: 0 0 auto;
    white-space: normal;
    word-break: break-word;
  }
}

/* Spinner für Ladevorgänge */
.loading-spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.spinner {
  margin-bottom: 10px;
  width: 40px;
  height: 40px;
  border: 4px solid #ccc;
  border-top-color: var(--primary-color);
  border-radius: 50%;
  animation: spin 0.6s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Scrollleisten für Dark Mode */
body.dark-mode::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

body.dark-mode::-webkit-scrollbar-track {
  background: var(--secondary-bg-color);
  border-radius: 4px;
}

body.dark-mode::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 4px;
}

body.dark-mode::-webkit-scrollbar-thumb:hover {
  background: var(--btn-primary-bg-hover);
}

body.dark-mode {
  scrollbar-width: thin;
  scrollbar-color: var(--primary-color) var(--secondary-bg-color);
}
