/* Card-Layout */
.fear-greed-card {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    background: transparent;
    border-radius: 10px;
    color: var(--text-color);
    box-shadow: var(--box-shadow);
    border-top: 0.5px solid var(--secondary-bg-color);
    border-bottom: 0.5px solid var(--secondary-bg-color);
}

/* Titel: links */
.chart-header2 {
    display: flex;
    justify-content: flex-start;
    /* nur links */
    align-items: center;
    margin-bottom: 15px;
}

.chart-header2 h3 {
    margin: 0;
    font-size: 1.5rem;
    color: var(--primary-color);
}

/* BODY: 2 Spalten (links gauge/info, rechts timeframe+chart) */
.fear-greed-body {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    /* Auf Handy => wrap */
}

/* LINKE SPALTE */
.fear-greed-left {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
    flex: 0 0 auto;
    /* fix */
}

.fear-greed-gauge {
    width: 150px;
    margin-top: 25px;
}

.fear-greed-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.fear-greed-classification {
    font-weight: bold;
    font-size: 1.3rem;
    margin-bottom: 5px;
}

.fear-greed-updated,
.fear-greed-nextupdate {
    font-size: 0.9rem;
    color: var(--subtext-color, #aaa);
}

/* RECHTE SPALTE: Timeframe oben, Chart drunter */
.fear-greed-right {
    display: flex;
    flex-direction: column;
    flex: 1 1 400px;
    /* wachsende Spalte */
    gap: 15px;
}

/* Timeframe-Select (rechtsbündig), 
     du kannst gerne justify-content: flex-end setzen,
     wenn es ganz am rechten Rand stehen soll. */
.timeframe-select-wrapper {
    display: flex;
    justify-content: flex-end;
}

/* Chart (fester Container, Overlay-Spinner) */
.fear-greed-chart {
    position: relative;
    min-height: 300px;
}

/* Overlay, wenn chartLoading=true */
.chart-loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
}

.chart-loading-text {
    color: #fff;
    font-size: 1rem;
}

/* Loading / Error */
.fear-greed-loading,
.fear-greed-error {
    margin: 10px 0;
    text-align: center;
    font-size: 1rem;
}

/* MOBILE */
@media (max-width: 768px) {
    .fear-greed-body {
        flex-direction: column;
        gap: 20px;
    }

    .fear-greed-chart {
        min-height: 340px;
    }

    /* Ggf. Select noch enger an den rechten Rand bringen */
    .timeframe-select-wrapper {
        justify-content: flex-end;
        margin-right: 0;
    }

    .chart-header2 h3 {
        font-size: 1.2rem;
        margin-left: -10px;
    }

    .fear-greed-left {
        flex-direction: row;
        align-items: center;
        gap: 15px; /* sorgt für Abstand zwischen Kreis und Infos */
      }

      .fear-greed-info {
        text-align: left; /* Falls du den Text linksbündig haben möchtest */
      }

    .fear-greed-gauge {
        width: 100px;
        margin-top: 8px;
    }
}