.info-tooltip-container {
  position: relative;
  display: inline-block;
}

.info-tooltip-content {
  position: absolute;
  top: 100%; /* Direkt unterhalb des Containers */
  right: 3px;  /* Rechts ausgerichtet */
  z-index: 1000;
  background: var(--background-color2);
  color: var(--text-color);
  border: 1px solid #ccc;
  padding: 10px;
  width: 250px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-size: 0.9rem;
  line-height: 1.3; /* Erhöhter Zeilenabstand */
}

/* Block-Elemente nur für echte Überschriften */
.info-tooltip-content h1,
.info-tooltip-content h2,
.info-tooltip-content h3 {
  text-decoration: none !important;
  display: block;
  margin-bottom: 8px;
}

/* Für starke Texte (wie deine UnderlinedStrong) inline behalten */
.info-tooltip-content strong {
  text-decoration: none !important;
  display: inline;
  margin-right: 2px;
  font-size: 1.08em; /* minimal größer als normaler Text */
}

/* Abstand für Absätze */
.info-tooltip-content p {
  margin: 8px 0;
}

/* Stil für das Fragezeichen-Icon */
.tooltip-icon {
  cursor: help;
  transition: color 0.2s ease;
  color: var(--text-color, #000);
}

.tooltip-icon:hover {
  color: var(--btn-primary-bg-hover, #007bff);
}

/* Zusätzlicher Abstand innerhalb des Tooltips */
.tooltip-inner-content {
  margin-top: 3px;
}

/* Positionierung des gesamten Tooltips */
.tooltip-wrapper {
  position: absolute;
  top: 10px;
  right: 10px;
}
