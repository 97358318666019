/* src/styles/GLOBALSTYLE_DARK.css */


body.dark-mode {
    --background-color: #1f1f1f;
    --background-color2: #2c2c2c;
    --background-gradient: linear-gradient(135deg, #2c2c2c 25%, #1f1f1f 100%);

    --text-color: #E0E0E0;
    --text-color-input: #343a40;

    --primary-color: #8A9BB2;
    --primary-hover-color: #7690A4;
    --secondary-bg-color: #3c3c3c;


    --price-up-color: lightgreen;
    --price-down-color: lightcoral;
    --price-up-bg: #22aa22;
    /* Entspricht #2a2 */
    --price-up-mid: #33ff33;
    /* Entspricht #3f3 */
    --price-down-bg: #993333;
    /* Entspricht #933 */
    --price-down-mid: #ff6666;
    /* Entspricht #f66 */

    --logout-color: #ff6666;
    --logout-hover-color: #ff3333;

    --box-shadow: none;
    --box-shadow-inset: inset 0 2px 5px rgba(255, 255, 255, 0.1);


    --btn-primary-bg: #8A9BB2;
    --btn-primary-bg-hover: #7690A4;
    --btn-secondary-bg: #5a6268;
    --btn-secondary-bg-hover: #4a4e52;

    --btn-success-bg: #28a745;
    --btn-success-bg-hover: #218838;
    --btn-success-text: #ffffff;


    /* Neue Toast-Variablen für Dark Mode */
    --toast-bg: #2c2c2c;
    --toast-text: #ffffff;
    --toast-success-bg: #335533;
    /* gedämpftes, dunkles Grün */
    --toast-error-bg: #553333;
    /* gedämpftes, dunkles Rot */
    --toast-info-bg: #333a55;
    /* gedämpftes, dunkles Blau-Lila */
    --toast-progress-bg: var(--primary-color);


    /* ICONS */
    --icon-delete: #ff6666;
    --icon-delete-hover: lightcoral;

    /* DANGER */
    --danger-text: lightcoral;

    /* LINKS */
    --link-color: #a3b9ff;       /* ein sanfter Blauton */
    --link-hover-color: #8da7e8; /* etwas dunkler beim Hover */


}